<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>

        <div class="highcharts-alternative-labels" v-if="flag">
            <div v-for="(item, index) in items" :key="index">
                {{ index + 1 }}&nbsp;&nbsp;&nbsp;{{ item.name }} {{ item.text }} {{ item.value }}
            </div>
        </div>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue';

export default {
    name: "CustomHighchartComponent",
    props: ['items', 'innerWidth'],
    components: {highcharts: Chart},
    data() {
        return {
            flag: false,
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 500,
                    backgroundColor: '#232429',
                    margin: [null]
                },

                title: {
                    text: ''
                },

                tooltip: {
                    enabled: true
                },

                xAxis: {
                    offset: 60,
                    categories: [],
                    labels: {
                        // useHTML: true,
                        // align: 'left',
                        verticalAlign: 'middle',
                        style: {
                            color: '#fff',
                            textAlign: 'left',
                            fontFamily: 'Satoshi',
                            paddingLeft: '20px',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }
                    },
                    lineWidth: 0
                },

                yAxis: [{
                    title: {
                        text: ''
                    },
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    lineWidth: 0
                }],

                plotOptions: {
                    series: {
                        borderRadius: {
                            radius: 10
                        },
                        stacking: 'normal',
                    },
                    column: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.text}',
                            verticalAlign: 'middle',
                            style: {
                                color: '#232429',
                                textAlign: 'center',
                                fontFamily: 'Satoshi',
                                fontSize: '26px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                textOutline: 'none',
                                whiteSpace: 'nowrap'
                            }
                        }
                    },
                    bar: {
                        useHTML: true,
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '',
                            verticalAlign: 'middle',
                            align: 'left',
                            x: 14,
                            style: {
                                color: '#232429',
                                fontFamily: 'Satoshi',
                                fontSize: '23px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                textOutline: 'none'
                            }
                        }
                    }
                },

                series: [{
                    name: '',
                    data: [],
                    color: '#AEC6F4',
                }],
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
            }
        }
    },

    methods: {
        changeInnerWidth() {

            if (this.innerWidth > 1600) {
                this.chartOptions.chart.height = 500;
                this.chartOptions.plotOptions.column.dataLabels.style.fontSize = '26px';
            }

            else if (this.innerWidth < 1600) {
                this.chartOptions.chart.height = 450;
                this.chartOptions.plotOptions.column.dataLabels.style.fontSize = '16px';
            }

            else if (this.innerWidth > 1100) {
                this.chartOptions.xAxis.labels.style.fontSize = '24px';
                this.chartOptions.xAxis.offset = 60;
            }

            else if (this.innerWidth < 1100 && this.innerWidth > 576) {
                this.chartOptions.chart.height = 400;
                this.chartOptions.xAxis.labels.style.fontSize = '16px';
                this.chartOptions.xAxis.offset = 20;
            }

            else if (this.innerWidth > 576) {
                this.flag = false;
                this.chartOptions.chart.height = 400;
                // this.chartOptions.chart.type = 'bar';
                this.chartOptions.chart.margin = [null];
                this.chartOptions.chart.backgroundColor = '#232429';
                this.chartOptions.xAxis.labels.enabled = true;
                this.chartOptions.plotOptions.bar.dataLabels.format = '';
                this.chartOptions.plotOptions.series.groupPadding = 0.3;
                this.chartOptions.plotOptions.series.pointPadding = 0.2;

            }

            if (this.innerWidth < 576) {
                this.flag = true;
                this.chartOptions.chart.height = 366;
                // this.chartOptions.chart.type = 'column';
                this.chartOptions.chart.margin = [0, 0, 0, 0];
                this.chartOptions.chart.backgroundColor = '#fff';
                this.chartOptions.xAxis.labels.enabled = false;
                this.chartOptions.plotOptions.bar.dataLabels.format = '{point.num}';
                this.chartOptions.plotOptions.series.groupPadding = 0.01;
                this.chartOptions.plotOptions.series.pointPadding = 0.05;
            }
        }
    },
    watch: {
        innerWidth: function () {
            this.changeInnerWidth();
        },
        items: function () {
            this.chartOptions.series[0].data = [];
            this.chartOptions.xAxis.categories = [];

            this.items.forEach((item, index) => {
                this.chartOptions.series[0].data.push({y: item.value, text: item.text, num: index + 1});
                this.chartOptions.xAxis.categories.push(item.name);
            })
        }
    },
    mounted() {
        this.changeInnerWidth();

        if (this.items.length > 0) {
            this.items.forEach((item, index) => {
                this.chartOptions.series[0].data.push({y: item.value, text: item.text, num: index + 1});
                this.chartOptions.xAxis.categories.push(item.name);
            })
        }
    }
}
</script>

<style>

.highcharts-alternative-labels {
    color: #232429;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
}

@media (max-width: 576px) {
    .third-component-chart {
        background: #fff !important;
        padding: 0 !important;
    }

    .highcharts-alternative-labels div {
        margin-bottom: 20px;
    }

    .highcharts-alternative-labels div {
        font-size: 18px;
    }
}


</style>