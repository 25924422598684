<template>
  <PageLayout>
    <div class="site-width schedule-demo-page">
      <div class="page-title">Start Free Trial</div>

      <b-form @submit.prevent="scheduleDemo" class="form-schedule-demo">
        <div class="form-schedule-demo-block">
          <b-form-group>
            <label> First Name </label>
            <b-form-input
              v-model="form.firstname"
              type="text"
              required
              autocomplete="off"
            />
          </b-form-group>

          <b-form-group>
            <label> Last Name </label>
            <b-form-input
              v-model="form.lastname"
              type="text"
              required
              autocomplete="off"
            />
          </b-form-group>
        </div>
        <div class="form-schedule-demo-block">
          <b-form-group>
            <label> Email </label>
            <b-form-input
              v-model="form.email"
              type="email"
              required
              autocomplete="off"
            />
          </b-form-group>
          <b-form-group class="empty-block"></b-form-group>
        </div>
        <div class="form-schedule-demo-block">
          <b-form-group>
            <label> Phone </label>
            <b-form-input
              v-model="form.phone"
              type="text"
              required
              autocomplete="off"
            />
          </b-form-group>
          <b-form-group class="empty-block"></b-form-group>
        </div>
        <div class="form-schedule-demo-block">
          <b-form-group>
            <label> Company </label>
            <b-form-input
              v-model="form.company"
              type="text"
              required
              autocomplete="off"
            />
          </b-form-group>
          <b-form-group class="empty-block"></b-form-group>
        </div>
        <div class="form-schedule-demo-block form-schedule-demo-block-btn">
          <button :disabled="loader" class="position-relative" type="submit">
            Start Free Trial
            <b-icon icon="arrow-right" class="arrow-right-animating"></b-icon>
            <b-spinner
              v-if="loader"
              class="spinner-btn ml-10"
              label="..."
            ></b-spinner>
          </button>
          <div></div>
        </div>
      </b-form>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/Layouts/PageLayout.vue";
import axios from "axios";
import methods from "@/Includes/util";
// import axios from "axios";

export default {
  name: "ScheduleDemo",
  components: {
    PageLayout,
  },

  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Schedule Demo - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      loader: false,
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
      },
    };
  },
  methods: {
    scheduleDemo() {
      this.loader = true;

      axios
        .post("https://media.adeasemediaresearch.com/index/demo", this.form)
        .then((res) => {
          if (res.data.status) {
            methods.makeSuccessToast(this, res.data.message);
          } else {
            methods.makeErrorToast(this, res.data.message);
          }
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
          this.loader = false;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.schedule-demo-page {
  min-height: 70vh;
}

.form-schedule-demo {
  width: 100%;
  max-width: 1520px;
}

.form-schedule-demo .form-group {
  width: 100%;
  margin-bottom: 40px;
}

.form-schedule-demo .form-group label {
  color: #232429;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 25px;
}

.form-schedule-demo .form-group input {
  border: none;
  border-bottom: 1px solid #aec6f4;
  border-radius: 0;
  padding: 10px 0;
  color: #232429;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}

.form-schedule-demo .form-group input:focus {
  box-shadow: none;
}

.form-schedule-demo .form-group input::placeholder {
  color: #232429;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}

.form-schedule-demo-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.form-schedule-demo-block-btn button,
.form-schedule-demo-block-btn div {
  width: 50%;
}

.form-schedule-demo-block-btn button {
  display: flex;
  padding: 16px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #232429;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.44px;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .form-schedule-demo-block-btn button,
  .form-schedule-demo-block-btn div {
    width: 100%;
  }

  .form-schedule-demo-block {
    flex-direction: column;
    gap: 0;
  }

  .empty-block {
    display: none;
  }
}
</style>
