<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/integrator-case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Unlocking Competitive Advantage: <br />
              Leveraging Integrated Data for Brand Success
            </div>
          </div>
        </div>
      </div>

      <div class="case-study-screen-component-block__blue background-white">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge AI
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In today's competitive market, brands seek innovative strategies
                to stand out and capture consumer attention.
              </div>
              <div>
                This case study explores how brands can utilize advertising
                occurrence data, provided by Adease’s advanced advertising
                intelligence tools, combined with their own first or third-party
                data, to gain a competitive edge.
              </div>
              <div>
                By integrating these rich data sources, brands can gain vital
                insights into their competitive landscape, identify strategic
                engagement opportunities, and maximize their advertising
                effectiveness.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/integrator-case-study-2.jpg"
        :text="'Key Performance Indicators (KPIs)<br> Enhanced with Integration'"
      />

      <CaseStudyBlockText title="Competitive Data" :items="items1" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Sales Information" :items="items2" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Media Ratings/GRP" :items="items3" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Brand Health Metrics" :items="items4" />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt"
      />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "IntegratorCaseStudyScreen",
  components: {
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Integrator Case Study - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "Utilizing advertising occurrence data through platforms like Adease, integrated with first or third-party data, equips brands with deep insights that enable strategic decision-making and enhance competitive positions. This data provides a comprehensive view of the advertising landscape, empowering brands to identify untapped opportunities, optimize their advertising strategies, and significantly strengthen their market presence.",
      items1: [
        {
          title: "Objective",
          text: "Compare performance with market rivals.",
        },
        {
          title: "Analysis",
          text: "Use integrated data to benchmark against competitors, identifying strengths and areas for improvement.",
        },
        {
          title: "Outcome",
          text: "By leveraging Adease's data alongside their internal metrics, Company X identified areas where they lagged behind competitors. This insight led to targeted improvements, resulting in a 20% increase in competitive standing.",
        },
      ],
      items2: [
        {
          title: "Objective",
          text: "Understand direct correlations between marketing efforts and sales outcomes.",
        },
        {
          title: "Analysis",
          text: "Combine sales data with advertising occurrence data to pinpoint which campaigns drive the most sales.",
        },
        {
          title: "Outcome",
          text: "Company X discovered that certain TV ads significantly boosted sales in specific regions. This insight allowed them to tailor their campaigns, resulting in a 15% increase in overall sales.",
        },
      ],
      items3: [
        {
          title: "Objective",
          text: "Gauge the effectiveness and reach of media campaigns.",
        },
        {
          title: "Analysis",
          text: "Integrate media ratings with occurrence data to optimize media spend for maximum ROI.",
        },
        {
          title: "Outcome",
          text: "By analyzing GRP alongside occurrence data, Company X optimized their media spend, achieving a 25% increase in campaign reach and a 30% improvement in ROI.",
        },
      ],
      items4: [
        {
          title: "Objective",
          text: "Monitor brand reputation and customer perceptions.",
        },
        {
          title: "Analysis",
          text: "Combine brand health metrics with advertising data to inform strategies that improve brand image.",
        },
        {
          title: "Outcome",
          text: "Through continuous monitoring and integration of customer feedback with advertising data, Company X improved their brand perception, leading to a 20% increase in positive brand sentiment.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
