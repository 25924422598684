<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/competitive-brands-case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Unlocking Competitive Advantage: Leveraging Advertising Occurrence
              Data for Brand Success
            </div>
          </div>
        </div>
      </div>

      <div class="case-study-screen-component-block__blue background-white">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge AI
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In today's fiercely competitive market, brands continually seek
                innovative strategies to stand out and capture consumer
                attention.
              </div>
              <div>
                This case study explores how brands can utilize advertising
                occurrence data, provided by Adease’s advanced advertising
                intelligence tools, to gain a competitive edge.
              </div>
              <div>
                By analyzing this rich data, brands can gain vital insights into
                their competitive landscape, identify strategic engagement
                opportunities, and maximize their advertising effectiveness.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/competitive-brands-case-study-2.jpg"
        :text="'Key Performance Indicators for <br> Competitive Analysis'"
      />

      <CaseStudyBlockText title="Market Penetration" :items="items1" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Share of Voice Among Competitors"
        :items="items2"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Competitor Cross-Engagement Rate"
        :items="items3"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Advertising Overlap" :items="items4" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Value Proposition Strength" :items="items5" />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt"
      />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "CompetitiveBrandsCaseStudyScreen",
  components: {
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Competitive Brands - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "Utilizing advertising occurrence data through platforms like Adease equips brands with deep insights that enable strategic decision-making and enhance competitive positions. This data provides a comprehensive view of the advertising landscape, empowering brands to identify untapped opportunities, optimize their advertising strategies, and strengthen their market presence significantly.",
      items1: [
        {
          title: "Objective",
          text: "Utilize competitive intelligence to discover gaps in the current advertising strategy and explore new market opportunities.",
        },
        {
          title: "Outcome",
          text: "Company A analyzed advertising occurrence data to identify under-served regions and demographic segments, leading to a targeted campaign that achieved a 15% increase in market penetration in previously untapped areas.",
        },
      ],
      items2: [
        {
          title: "Objective",
          text: "Increase the brand’s visibility to ensure it is more prominent than competitors in the marketplace.",
        },
        {
          title: "Outcome",
          text: "Company A reviewed its advertising frequency and scope against its competitors, identifying a need for increased presence within key demographics. By reallocating resources to prime time slots, the company increased its share of voice by 20%, enhancing market visibility and consumer engagement.",
        },
      ],
      items3: [
        {
          title: "Objective",
          text: "Analyze the advertising landscape to identify which competitors occupy the same advertising spaces and times as your brand.",
        },
        {
          title: "Outcome",
          text: "Company A used advertising tracking data to pinpoint time slots heavily dominated by competitors and strategically shifted their ad timings to less saturated periods. This resulted in a 30% improvement in viewer engagement rates.",
        },
      ],
      items4: [
        {
          title: "Objective",
          text: "Determine the overlap in advertising stations and times between your brand and comparable competitors to strategize distinct visibility or collaboration.",
        },
        {
          title: "Outcome",
          text: "Company A analyzed overlap with direct competitors and chose to pursue alternative channels that were less crowded, leading to a 25% increase in ad recall rate among targeted consumers.",
        },
      ],
      items5: [
        {
          title: "Objective",
          text: "Enhance the impact of your advertisements by benchmarking their performance against similar brands.",
        },
        {
          title: "Outcome",
          text: "Company A utilized feedback mechanisms and tracking data to evaluate the reception of their ads. The insights gained helped refine their messaging, resulting in a 40% increase in consumer satisfaction and a 35% boost in conversion rates.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
