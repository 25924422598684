<template>
  <nav>
    <ul class="menu-block-items">
      <li class="menu-block__item">
        <router-link to="/company"> Company </router-link>
      </li>
      <li class="menu-block__item">
        <!--            <router-link to="/solutions">-->
        <!--                Solutions-->
        <!--            </router-link>-->
        <b-dropdown
          ref="solutionsDropdown"
          class="solutions-dropdown"
          text="Solutions"
          variant="link"
          no-caret
        >
          <b-dropdown-item to="/integrator"> Integrator </b-dropdown-item>
          <b-dropdown-item to="/verification"> Verification </b-dropdown-item>
          <b-dropdown-item to="/competitive-data">
            Competitive Data
          </b-dropdown-item>
          <b-dropdown-item to="/creative-services">
            Creative Services
          </b-dropdown-item>
          <b-dropdown-item to="/competitive-pricing">
            Competitive Pricing
          </b-dropdown-item>
          <b-dropdown-item to="/prospector"> Prospector </b-dropdown-item>
          <li role="presentation" @click="closeDropdown">
            <a
              href="https://aurbit.ai"
              class="dropdown-item"
              role="menuitem"
              rel="noopener"
              target="_blank"
            >
              Aurbit
            </a>
          </li>
          <li role="presentation" @click="closeDropdown">
            <a
              href="https://spotliteai.com"
              class="dropdown-item"
              role="menuitem"
              rel="noopener"
              target="_blank"
            >
              Spotlite
            </a>
          </li>
<!--          <b-dropdown-item to="/case-studies"> Case Studies </b-dropdown-item>-->
        </b-dropdown>
      </li>
      <li class="menu-block__item menu-block__item-div">
        <a
          href="#our-latest-insights"
          @click="(e) => scrollTo('our-latest-insights', e)"
        >
          Latest Insights
        </a>
      </li>
      <li class="menu-block__item">
        <router-link to="/contact"> Contact </router-link>
      </li>

      <AnimatingButton
        text="Login"
        class="login-animating-button"
        :class="
          $store.getters['Flags/getMenuFlag']
            ? 'active-menu-animating-button'
            : ''
        "
        @click="openModal"
      />

      <LoginComponent />
    </ul>
  </nav>
</template>

<script>
import LoginComponent from "@/components/modal/LoginComponent.vue";
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";
import methods from "@/Includes/util";

export default {
  name: "MainMenuComponent",
  computed: {
    methods() {
      return methods;
    },
  },
  components: { AnimatingButton, LoginComponent },
  data() {
    return {
      url: this.$route.name,
    };
  },
  methods: {
    openExternalLink(url) {
      window.open(url, "_blank");
      this.$refs.solutionsDropdown.hide();
    },

    closeDropdown() {
      this.$refs.solutionsDropdown.hide();
    },

    openModal() {
      this.$bvModal.show("login");
    },

    navigateToAboutAndScroll(id) {
      this.$router.push("/", () => {
        setTimeout(() => {
          this.scrollToAboutComponent(id);
        }, 500);
      });
    },
    scrollToAboutComponent(id) {
      const targetElement = document.getElementById(id);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },

    scrollTo(id, event) {
      event.preventDefault();

      if (this.$route.name === "HomeScreen") {
        this.scrollToAboutComponent(id);
      } else {
        this.navigateToAboutAndScroll(id);
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
.menu-block__item {
  list-style-type: none;
}

.menu-block-items {
  margin: 0;
  display: flex;
  position: relative;
  align-items: center;
  z-index: 1;
}

.menu-block__item {
  margin-right: 2.8vw;
}

.menu-block__item-div {
  height: 28.79px;
}

.menu-block__item button {
  display: inline-block;
  padding: 0;
  border-radius: 0;
}

.menu-block__item button:focus {
  outline: none;
  box-shadow: none;
}

.menu-block__item a,
.menu-block__item button,
.menu-block__item-div div {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-bottom: 5px;
  cursor: pointer;
  display: inline-block;
}

.menu-block__item a:hover,
.menu-block__item button:hover,
.menu-block__item-div {
  text-decoration: none;
  color: #fff;
  display: inline-block;
}

.menu-block-items .animating-button {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  text-transform: uppercase;
}

.menu-block-items a:hover,
.menu-block__item button:hover,
.menu-block__item-div div:hover,
.menu-block-items .router-link-exact-active {
  color: #fff !important;
  text-decoration: none;
  border-bottom: 2px solid #fff !important;
}

.solutions-dropdown a {
  color: #000;
  text-align: left;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent !important;
}

.solutions-dropdown a:hover {
  color: #000 !important;
  border: none !important;
}

.solutions-dropdown .router-link-exact-active {
  color: #000 !important;
  border: none !important;
  background: #e9ecef;
}

@media (min-width: 1920px) {
  .menu-block__item {
    margin-right: 52px;
  }
}

@media (max-width: 1600px) {
  .menu-block__item a,
  .menu-block__item button,
  .menu-block__item-div div {
    font-size: 18px;
  }

  .login-animating-button .animating-button {
    font-size: 18px;
    padding: 10px 50px;
  }
}

@media (max-width: 450px) {
}
</style>
