import Vue from "vue";
import App from "@/App.vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueAgile from "vue-agile";
import store from "@/Store";
import VueCookies from "vue-cookies";
import Clipboard from "v-clipboard";
import axios from "axios";
import VueRouter from "vue-router";
import routes from "@/router";
import HighchartsVue from "highcharts-vue";
import VueAnimateOnScroll from "vue-animate-onscroll";
import VueCarousel from "vue-owl-carousel";
import VueMeta from "vue-meta";
import VueMarqueeSlider from "vue-marquee-slider";
import Marquee3k from "marquee3000";

import "@/css/app.css";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response;
    if (status === 401) {
      // do stuff
    }
  }
);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAgile);
Vue.use(VueCookies, { expire: "7d" });
Vue.use(Clipboard);
Vue.use(HighchartsVue);
Vue.use(VueAnimateOnScroll);
Vue.component("vue-owl-carousel", VueCarousel);
Vue.use(VueMeta);
Vue.use(VueMarqueeSlider);
setTimeout(() => {
  Marquee3k.init();
}, 200);

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
  router: routes,
}).$mount("#app");
