<template>
  <div class="third-component">
    <div class="home-component-title">
      <h2>Top 10 {{ isRadioItems ? "Radio" : "TV" }} Advertisers in Canada</h2>
    </div>
    <div class="third-component-chart__text">
      <span>Week of {{ period }}</span>
    </div>

    <div class="chnge-chart-data">
      <div
        @click="toggleItems(1)"
        :class="isTvItems ? 'active-chart-data' : ''"
        class="chnge-chart-data__item"
      >
        <h3>TV</h3>
      </div>
      <div
        @click="toggleItems(2)"
        :class="isRadioItems ? 'active-chart-data' : ''"
        class="chnge-chart-data__item"
      >
        <h3>Radio</h3>
      </div>
    </div>

    <div class="third-component-chart">
      <div>
        <div class="third-component-chart__chart" v-show="isTvItems">
          <CustomHighchartComponent :innerWidth="innerWidth" :items="tvItems" />
        </div>

        <div class="third-component-chart__chart" v-show="isRadioItems">
          <CustomHighchartComponent
            :innerWidth="innerWidth"
            :items="radioItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHighchartComponent from "@/components/homePage/CustomHighchartComponent.vue";

export default {
  name: "ThirdComponent",
  components: { CustomHighchartComponent },
  props: ["radioItems", "tvItems", "period", "innerWidth"],
  data() {
    return {
      isRadioItems: false,
      isTvItems: true,
    };
  },
  methods: {
    toggleItems(num) {
      if (num === 1) {
        this.isTvItems = true;
        this.isRadioItems = false;
      }

      if (num === 2) {
        this.isRadioItems = true;
        this.isTvItems = false;
      }
    },
  },
};
</script>

<style>
.third-component {
  margin-top: 120px;
}

.third-component .home-component-title {
}

.third-component .home-component-text {
  margin-bottom: 60px;
}

.third-component-chart {
  background: #232429;
  padding: 80px;
  color: #fff;
}

.third-component-chart__text {
  color: #828282;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
  margin: 24px 0 80px;
}

.third-component-chart__title {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  text-align: center;
}

.third-component-chart__title0 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  margin-bottom: 10px;
}

.third-component-chart__chart {
  padding: 0;
}

.chnge-chart-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chnge-chart-data__item {
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -2px;
  background: #2e2f34;
  backdrop-filter: blur(60px);
  padding: 40px;
  border: none;
}

.active-chart-data {
  color: #aec6f4;
  background: #373c47;
}

@media (max-width: 1600px) {
  .third-component-chart__text {
    font-size: 25px;
    margin: 25px 0 50px;
  }

  .third-component {
    margin-top: 60px;
  }

  .third-component-chart {
    padding: 30px 30px;
  }
}

@media (max-width: 1300px) {
  .third-component {
    margin-top: 100px;
  }

  .third-component-chart {
    padding: 41px;
  }

  .third-component-chart__chart {
    padding: 0;
  }
}

@media (max-width: 992px) {
  .third-component {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .third-component-chart {
    padding: 30px;
  }

  .third-component-chart__text {
    font-size: 20px;
    margin: 0 0 40px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .third-component .home-component-title {
    color: #232429;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.28px;
  }
}

@media (max-width: 576px) {
  .third-component-chart__title {
    color: #232429;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.2px;
    text-align: left;
  }

  .third-component .home-component-title {
    color: #232429;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.28px;
  }
}
</style>
