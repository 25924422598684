<template>
  <AppLayout>
    <div class="prospector-screen-component">
      <div
        class="prospector-screen-component-head"
        style="background-image: url('/img/ourSolutionsPage/Integrator.jpg')"
      >
        <div class="prospector-screen-component-head-content">
          <div class="prospector-screen-component-head-block">
            <div class="prospector-screen-component-head-title">
              <h1>SOLUTIONS</h1>
            </div>

            <div class="prospector-screen-component-head-text">
              <h2>Integrator</h2>
            </div>

            <div class="prospector-screen-component-head-description">
              <p>Maximizing Marketing Effectiveness with Unified Data.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="site-width prospector-screen-component-block">
        <div class="prospector-screen-component-block-title">
          <h2>Integrate Your Data: Harness the Power of Unified Insights</h2>
        </div>
        <div class="prospector-screen-component-block-text">
          <p>
            With Adease's simplistic and advanced technologies, we enable
            seamless integration of your first and third-party data, allowing
            you to analyze all data in a single, comprehensive dashboard. This
            unified approach provides a holistic view of your marketing
            performance, empowering you with the best decision-making solutions.
          </p>
        </div>
        <div class="prospector-screen-component-block-border"></div>

        <div class="prospector-screen-component-block-title">
          <h2>Why Integrate?</h2>
        </div>
        <div class="prospector-screen-component-items">
          <div
            v-for="(item, key) in items"
            :key="key"
            class="prospector-screen-component-item"
          >
            <img src="/img/ourSolutionsPage/Ellipse 20.png" alt="Ellipse" />
            <div>
              <div class="prospector-screen-component-item-title">
                <h3>{{ item.title }}</h3>
              </div>
              <div class="prospector-screen-component-item-text">
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="prospector-screen-component-block-border"></div>

        <div class="prospector-screen-component-block-title">
          <h2>Key Benefits of our Integrator Tool</h2>
        </div>
        <div class="prospector-screen-component-items">
          <div
            v-for="(item, key) in items2"
            :key="key"
            class="prospector-screen-component-item"
          >
            <img src="/img/ourSolutionsPage/Ellipse 20.png" alt="Ellipse" />
            <div>
              <div class="prospector-screen-component-item-title">
                <h3>{{ item.title }}</h3>
              </div>
              <div class="prospector-screen-component-item-text">
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="prospector-screen-component-block-text">
          <p>
            By integrating diverse data sources, brands can ensure that every
            advertising decision is backed by holistic insights, driving maximum
            ROI and achieving sustained market success. Equip your team with the
            tools to navigate the competitive landscape with confidence and
            precision.
          </p>
        </div>
        <AnimatingButton
          text="VIEW CASE STUDY"
          @click="() => $router.push({ name: 'IntegratorCaseStudyScreen' })"
        />
      </div>

      <ContactUsComponent />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import ContactUsComponent from "@/components/homePage/ContactUsComponent.vue";
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "IntegratorScreen",
  components: { AnimatingButton, ContactUsComponent, AppLayout },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Integrator - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      items: [
        {
          title: "Holistic View",
          text: "Combine various data sources to gain a comprehensive picture of your marketing performance, allowing for more precise and informed decision-making.",
        },
        {
          title: "Informed Decisions",
          text: "Use integrated insights to drive strategic marketing actions, ensuring every decision is backed by complete and accurate data.",
        },
        {
          title: "ROI Measurement",
          text: "Accurately measure the return on every marketing investment, optimizing your budget allocation for maximum impact.",
        },
        {
          title: "Enhanced Strategy Outcomes",
          text: "By integrating diverse data sources, ensure every advertising decision is supported by comprehensive insights, driving maximum ROI and achieving sustained market success.",
        },
      ],
      items2: [
        {
          title: "Unified Data Dashboard",
          text: "Access a personalized dashboard that integrates various data sources, giving you a complete view of your marketing performance and competitive landscape.",
        },
        {
          title: "Strategic Insights",
          text: "Leverage the power of unified data to uncover insights that drive strategic actions, optimize campaigns, and maximize ROI.",
        },
        {
          title: "Actionable Intelligence",
          text: "Utilize integrated data to make informed decisions, ensuring that every aspect of your marketing strategy is based on comprehensive and accurate information.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.prospector-screen-component {
}

.prospector-screen-component-head {
  position: relative;
  margin-top: -108px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.prospector-screen-component-head-content {
  width: 100%;
  padding: 90px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(60px);
}

.prospector-screen-component-head-block {
  max-width: 1520px;
  margin: 0 auto;
}

.prospector-screen-component-head-title {
  color: #aec6f4;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1.28px;
  margin-bottom: 32px;
}

.prospector-screen-component-head-text {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
  letter-spacing: -2.56px;
  margin-bottom: 24px;
}

.prospector-screen-component-head-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
}

.prospector-screen-component-items {
  margin-top: 40px;
  padding: 0 14px;
}

.prospector-screen-component-item {
  display: flex;
  align-items: flex-start;
  gap: 22px;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.prospector-screen-component-item img {
  padding-top: 14px;
}

.prospector-screen-component-item-title {
  color: #232429;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -2px;
  margin-bottom: 11px;
}

.prospector-screen-component-item-text,
.prospector-screen-component-block-text {
  color: #4d4d4d;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.prospector-screen-component-block {
  margin-top: 160px;
  margin-bottom: 160px;
}

.prospector-screen-component-block .animating-button {
  margin-top: 100px;
  width: 100%;
}

.prospector-screen-component-block-title {
  color: #232429;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -2.08px;
  margin-bottom: 32px;
}

.prospector-screen-component-block-border {
  width: 100%;
  margin: 64px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 1300px) {
  .prospector-screen-component-head {
    height: 800px;
  }

  .prospector-screen-component-head-content {
    padding: 52px 60px;
  }

  .prospector-screen-component-head-title {
    font-size: 22px;
    margin-bottom: 8px;
    letter-spacing: -0.44px;
  }

  .prospector-screen-component-head-text {
    font-size: 48px;
    margin-bottom: 16px;
    line-height: 64px;
    letter-spacing: -1.92px;
  }

  .prospector-screen-component-head-description {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  .prospector-screen-component-block-title {
    color: #232429;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -1.92px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    letter-spacing: -0.2px;
  }

  .prospector-screen-component-item-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

@media screen and (max-width: 992px) {
  .prospector-screen-component-head {
    height: 720px;
  }

  .prospector-screen-component-head-content {
    padding: 52px 60px;
  }

  .prospector-screen-component-head-title {
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    margin-bottom: 8px;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-head-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  .prospector-screen-component-item-title {
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

@media screen and (max-width: 768px) {
  .prospector-screen-component-head {
    height: 650px;
  }

  .prospector-screen-component-head-content {
    padding: 40px;
  }

  .prospector-screen-component-head-title {
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-head-description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .prospector-screen-component-block-border {
    margin: 40px 0;
  }

  .prospector-screen-component-item-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.36px;
  }
}

@media screen and (max-width: 500px) {
  .prospector-screen-component-head {
    height: 790px;
  }

  .prospector-screen-component-head-content {
    padding: 32px;
  }

  .prospector-screen-component-head-title {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 24px;
    letter-spacing: -0.54px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }

  .prospector-screen-component-head-description {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.64px;
    margin-bottom: 24px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.18px;
  }

  .prospector-screen-component-item-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1.2px;
  }
}
</style>
