const state = () => ( {
    menuFlag: false
});

const getters = {
    getMenuFlag(state) {
        return state.menuFlag;
    }
};

const mutations = {
    changeMenuFlag(state, n) {
        state.menuFlag = n;
    }
};

const actions = {
    changeMenuFlag({commit}, n) {
        commit('changeMenuFlag', n);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
