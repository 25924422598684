<template>
  <div class="plan-better-component">
    <div class="home-component-title">
      <h2>Plan Better, Gain a Competitive Edge, and Secure Qualified Leads</h2>
    </div>
    <div class="plan-better-items">
      <div
        v-for="item in items"
        :key="item.id"
        class="plan-better-item"
        :style="{ transitionDelay: `${item.id * 0.2}s` }"
      >
        <div class="plan-better-item-content">
          <div class="plan-better-item-title">
            <h3>{{ item.title }}</h3>
          </div>
          <div class="plan-better-item-text">
            <p>{{ item.text }}</p>
          </div>
        </div>

        <AnimatingButton
          text="Learn More"
          class="plan-better-item-button"
          @click="openPage"
        />

        <img
          src="/img/icons/3.png"
          alt="icons"
          class="plan-better-component-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "PlanBetterComponent",
  components: { AnimatingButton },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Brands",
          text: "Utilize deep insights into market trends to craft campaigns that not only reach but also deeply resonate with your audience. Elevate your brand's presence and maximize ROI by making data-driven decisions that set you apart from the competition.",
        },
        {
          id: 2,
          title: "Media Planners",
          text: "Optimize your ad placements with a granular understanding of the advertising ecosystem. Adjust strategies in real-time based on comprehensive market analysis, ensuring every ad placement is perfectly timed and targeted for maximum effectiveness.",
        },
        {
          id: 3,
          title: "Media Companies",
          text: "Transform advertising intelligence into a powerful tool for generating high-quality leads. Identify and connect with businesses seeking your unique advertising opportunities, creating partnerships that drive mutual success.",
        },
        {
          id: 4,
          title: "Resellers",
          text: "Enhance your offerings by incorporating real-time advertising intelligence. Provide your clients with the competitive edge they need to thrive, establishing your firm as a pivotal resource in the marketplace, while saving the time and resources of in-house operations.",
        },
      ],
    };
  },
  methods: {
    openPage() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style lang="scss">
.animated {
  .plan-better-component {
    .plan-better-item {
      opacity: 1;
    }
  }
}

.plan-better-item {
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.plan-better-component {
  padding: 120px 0;
}

.plan-better-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 20px;
  margin-top: 40px;
  transition: 0.5s;
}

.plan-better-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 32px;
  gap: 16px;
  background: #232429;
  width: 24%;
  min-width: 310px;
  transition: 0.5s;
}

.plan-better-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.plan-better-item-title {
  color: #aec6f4;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -2px;
}

.plan-better-item-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.2px;
  margin-bottom: 137px;
}

.plan-better-component-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
}

.plan-better-item-button .animating-button {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  background: transparent;
  padding: 8px 10px;
  border: 1px solid transparent;
}

.plan-better-item-button .animating-button:hover {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
}

@media (max-width: 1600px) {
  .plan-better-component {
    padding: 60px 0;
  }

  .plan-better-item {
    min-width: 280px;
  }

  .plan-better-item-title {
    font-size: 29px;
  }

  .plan-better-item-button .animating-button {
    font-size: 15px;
  }
}

@media (max-width: 1500px) {
  .plan-better-item {
    min-width: 260px;
  }
}

@media (max-width: 1300px) {
  .plan-better-item {
    width: 48% !important;
  }
}

@media (max-width: 992px) {
  .plan-better-item {
    width: 100% !important;
  }
}
</style>
