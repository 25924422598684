<template>
  <div class="animating-button-block">
    <button
      class="animating-button"
      type="submit"
      @mouseenter="showArrow = true"
      @mouseleave="showArrow = false"
    >
      <span class="animating-button-text">{{ text }}</span>
      <b-icon
        icon="arrow-right"
        class="arrow-right-animating"
        :class="{ 'show-arrow': showArrow }"
      ></b-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: "AnimatingBTN",
  props: ["text"],
  data() {
    return {
      showArrow: false,
    };
  },
};
</script>

<style>
.animating-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.animating-button-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.animating-button {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.44px;
  cursor: pointer;
  transition: background 0.5s ease;
  padding: 16px 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #232429;
  background: #fff;
}

.animating-button:hover {
  background: #232429;
  color: #fff;
}

.animating-button-block .arrow-right-animating {
  display: inline-block;
  width: 0;
  transition: margin-left 0.5s ease;
  opacity: 0;
  color: #fff;
}

.animating-button-block .show-arrow {
  /* width: 22px;
    margin-left: 10px; */
  opacity: 1;
}

.animating-button {
  font-size: 16px;
  padding: 8px 28px;
}
</style>
