<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/Prospector-Case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Prospector KPI’S
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockKPI
        title="Enhancing Competitive Positioning in Broadcasting Using Tailored KPI"
        text1="Advertiser engagement"
        text2="Delivering actionable insights that enable stations to improve their market stance"
        text3="Competitive analysis"
      />

      <div class="case-study-screen-component-block__blue">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge Al
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In a highly competitive advertising industry, stations are
                continually seeking ways to differentiate themselves and capture
                a larger share of advertising revenue.
              </div>
              <div>
                This case study explores a suite of Key Performance Indicators
                (KP|s) specifically designed for media owners to assess their
                competitive landscapes and identify opportunities from
                advertising intelligence to attract advertisers currently
                engaged with competitors or similar stations.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/Prospector-Case-study-2.jpg"
        :text="'Key Performance Indicators for<br>Competitive Prospecting'"
      />

      <CaseStudyBlockText
        title="Market penetration"
        :items="items1"
        purpose="This analysis will pinpoint gaps in advertiser engagement and highlight key prospects that your team should target."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Share of ad activity among competitors"
        :items="items2"
        purpose="This will illuminate your station's share of advertising and identify opportunities for strategic enhancement."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Competitor cross-engagement rate"
        :items="items3"
        purpose="This helps identify dual-engagement opportunities and the potential to transition these into exclusive partnerships."
      />

      <CaseStudyBlockImage
        class="case-study-block-image-mt"
        image="/img/ourSolutionsPage/Prospector-Case-study-3.jpg"
        :text="'Key Performance Indicators for<br>Prospecting Similar Stations'"
      />

      <CaseStudyBlockText
        title="Advertising overlap"
        :items="items4"
        purpose="This analysis provides a foundation for developing strategies that highlight your competitive advantages."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Value proposition strength"
        :items="items5"
        purpose="This assessment helps determine the appeal of your advertising options and identifies areas ripe for innovation."
      />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt"
      />

      <!--            <CaseStudyBlockContacts class="site-width"/>-->
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";
// import CaseStudyBlockContacts from "@/components/CaseStudyBlockContacts.vue";
import CaseStudyBlockKPI from "@/components/CaseStudyBlockKPI.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "CaseStudyScreen",
  components: {
    CaseStudyBlockKPI,
    // CaseStudyBlockContacts,
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Case Study - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "These KPIs provide a robust framework for utilizing integrated analytics to analyze and enhance a station's competitive positioning and strategic engagement with advertisers. By consistently implementing these KP|s, stations can uphold data-driven strategies that dynamically adapt to market conditions, thereby effectively securing new business and strengthening their market presence.",
      items1: [
        {
          title: null,
          text: "Identify advertisers who are currently working with competitors but not with your station.",
        },
        {
          title: null,
          text: "Analyze this data to compile a list of advertisers engaged with competitors and cross-reference it with your station's advertisers.",
        },
      ],

      items2: [
        {
          title: null,
          text: "Assess your station’s share of the advertising volume compared to that of competitors.",
        },
        {
          title: null,
          text: "Analyze the total advertising volume for each station to determine your station’s share of the advertising airspace.",
        },
      ],
      items3: [
        {
          title: null,
          text: "Identify advertisers who engage with both your station and at least one competitor.",
        },
        {
          title: null,
          text: "Examine the overlap of advertisers who have placements with both your station and competitors.",
        },
      ],
      items4: [
        {
          title: null,
          text: "Quantify the overlap of advertisers between your station(s) and stations with similar profiles.",
        },
        {
          title: null,
          text: "Identify advertisers that are common to both your station and these similar stations.",
        },
      ],
      items5: [
        {
          title: null,
          text: "Evaluate how advertisers perceive the benefits of your advertising options in comparison to those offered by similar stations.",
        },
        {
          title: null,
          text: "Utilize survey results or feedback scores to assess advertiser satisfaction and the perceived value of your offerings relative to competitors. Track these trends over time to monitor changes in advertiser perceptions.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
