<template>
    <div class="header d-flex justify-content-between align-items-center">
        <a href="/" class="logo-img">
            <img src="/img/logo-black.png" alt="">
        </a>
        <div class="d-flex align-items-center justify-content-end">
            <div class="menu-block">
                <MainMenuComponent/>
            </div>
            <div class="menu-burger-block">
                <input type="checkbox" id="myInput" ref="myInput">
                <label for="myInput" @click="openMenu" class="menu-burger">
                    <img src="/img/menu-b.png" class="menu-img" alt="">
                </label>

                <BurgerMenuComponent/>

                <label for="myInput" @click="openMenu" class="menu-burger-close">
                    <img src="/img/menu-close.png" class="menu-img" alt="">
                </label>
            </div>
        </div>
    </div>
</template>

<script>


import MainMenuComponent from "@/components/MainMenuComponent.vue";
import BurgerMenuComponent from "@/components/BurgerMenuComponent.vue";

export default {
    name: "PageHeaderComponent",
    props: [],
    components: {BurgerMenuComponent, MainMenuComponent},
    data() {
        return {}
    },
    methods: {
        openModal() {
            this.$bvModal.show('login');
        },
        openMenu() {
            let flag = this.$store.getters['Flags/getMenuFlag'];
            this.$store.dispatch("Flags/changeMenuFlag", !flag);
        },
    }
}
</script>

<style>
.header {
    padding: 20px 80px;
}

.logo-img {
    z-index: 1;
}

.logo-img img {
    width: 120px;
}

.btn-login {
    background: #fff;
    width: 160px;
    height: 56px;
    color: rgb(35, 36, 41);
    font-size: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
    cursor: pointer;
}

.btn-login:hover {
    text-decoration: none;
    color: rgb(35, 36, 41);
}

.menu-img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.active-menu-btn {
    background: #232429;
    color: #fff;
}

.active-menu-btn:hover {
    text-decoration: none;
    color: #fff;
}

.menu-burger-block {
    z-index: 5;
    margin-left: 40px;
    display: none;
}

@media (max-width: 768px) {
    .menu-burger-block{
        display: block;
    }
}

</style>