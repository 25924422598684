<template>
  <AppLayout>
    <div class="about-page">
      <div class="about-page-header">
        <img src="/img/compony-page-1.png" alt="" />
        <!--                <div class="page-title text-capitalize" ref="aboutPageTitle">-->
        <!--                    Adease media research-->
        <!--                </div>-->
      </div>

      <div class="site-width" ref="aboutPageContent">
        <div class="about-page-content">
          <div class="page-title about-page-content-block">
            <h2>Advancing Technology for Advertising Intelligence</h2>
          </div>
          <div class="about-page-content__text about-page-content-block"></div>
        </div>
        <div class="about-page-content">
          <div class="about-page-content-block about-page-content-block-img">
            <img
              class="about-page-content-img"
              src="/img/compony-page-2.png"
              alt=""
            />
          </div>
          <div class="about-page-content-block">
            <div class="about-page-content__text">
              <p>
                Adease Media Intelligence Inc. is the leading market
                intelligence company specializing in comprehensive advertising
                data across various platforms: radio, TV, print, flyers, online,
                and e-commerce.
              </p>
            </div>
            <div class="about-page-content__text mt-16">
              <p>
                Our proprietary technology streamlines processes, automates
                tasks, and delivers tailored insights to meet each client's
                unique requirements. With a focus on flexibility and precision,
                we guarantee data accuracy and timeliness through our advanced
                methodologies and global partnerships, surpassing industry
                standards.
              </p>
            </div>
            <div class="about-page-content__text mt-16">
              <p>
                Annually, we meticulously collect and report over 30 million
                instances of advertisements spanning TV, radio, print, online,
                and e-commerce in Canada and the US. Our commitment to
                innovative technology, customized solutions, and unparalleled
                accuracy ensures that our clients receive the most relevant and
                reliable intelligence for their strategic decision-making
                processes.
              </p>
            </div>
          </div>
        </div>
        <div class="about-page-content__text2">
          <h2>Let Us Help You Grow Your Business</h2>
        </div>

        <AnimatingButton
          text="Contact Us!"
          class="about-page-animating-button"
          @click="openPage"
        />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";
import AppLayout from "@/Layouts/AppLayout.vue";

export default {
  name: "AboutScreen",
  components: { AppLayout, AnimatingButton },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        { name: "description", content: this.seo.description },
        { property: "og:title", content: this.seo.title },
        { property: "og:description", content: this.seo.description },
        { name: "twitter:title", content: this.seo.title },
        { name: "twitter:description", content: this.seo.description },
        { name: "keywords", content: this.seo.keywords },
        // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Company - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      innerWidth: window.innerWidth,
    };
  },
  methods: {
    openPage() {
      this.$router.push("/contact");
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    changeInnerWidth() {
      let aboutPageContent = this.$refs.aboutPageContent;
      if (this.innerWidth > 1300) {
        this.$refs.aboutPageTitle.style.left =
          aboutPageContent.offsetLeft + "px";
      } else {
        this.$refs.aboutPageTitle.style.left = "80px";
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.changeInnerWidth();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
  },
};
</script>

<style>
.about-page {
  margin-top: -108px;
}

.about-page-content-block-img {
  height: 545px;
  overflow: hidden;
  position: relative;
}

.about-page-content-block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.about-page-content-img {
  width: 100%;
}

.about-page-header {
  position: relative;
  height: 830px;
  overflow: hidden;
}

.about-page-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.about-page-header .page-title {
  color: #fff;
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 115px;
  letter-spacing: -1px;
  margin-bottom: 0;

  position: absolute;
  bottom: 60px;
  left: 80px;
}

.about-page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.about-page-content .page-title {
  color: #232429;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0;
}

.about-page-content__text {
  color: #232429;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  padding-left: 60px;
}

.about-page-content__text2 {
  margin-bottom: 40px;
  margin-top: 60px;
  color: #232429;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.about-page-content-block {
  width: 50%;
}

.about-page-animating-button {
  margin-top: 40px;
  margin-bottom: 150px;
}

@media (max-width: 1300px) {
  .about-page-header .page-title {
    font-size: 65px;
    left: 62px !important;
  }
}

@media (max-width: 1200px) {
  .about-page-content-block-img {
    height: 502px;
  }
}

@media (max-width: 992px) {
  .about-page-content__text2 {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .about-page-content-block-img {
    height: 331px;
  }

  .about-page-animating-button {
    margin-bottom: 40px;
  }

  .about-page-header .page-title {
    color: #fff;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.28px;
    bottom: 13px;
    left: 31px !important;
  }

  .about-page-content .page-title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .about-page-content {
    flex-direction: column;
  }

  .about-page-content-block {
    width: 100%;
  }

  .about-page-content__text2 {
    margin-bottom: 100px;
  }

  .about-page-content__text {
    padding-left: 0;
    margin-top: 16px;
  }
}

@media (max-width: 576px) {
  .about-page .animating-button {
    width: 100%;
  }

  .about-page-header {
    height: 396px;
  }

  .about-page-header .page-title {
    font-size: 28px;
    left: 16px !important;
  }

  .about-page-content-block-img {
    height: 502px;
  }
}

@media (max-width: 500px) {
  .about-page-content-block-img {
    height: 100%;
  }
  .about-page-content-block-img img {
    object-fit: contain;
    position: relative;
  }
}
</style>
