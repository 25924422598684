<template>
    <div class="body min-h-100 overflow-x-hidden">
        <HeaderComponent/>
        <slot></slot>
        <!--        <ScheduleDemoComponent/>-->
        <FooterComponent/>
    </div>

</template>

<script>

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
// import ScheduleDemoComponent from "@/components/homePage/ScheduleDemoComponent.vue";

export default {
    name: "AppLayout",
    components: {
        // ScheduleDemoComponent,
        FooterComponent,
        HeaderComponent
    },
    data() {
        return {}
    },
    methods: {},
    mounted() {
        this.$store.dispatch("Flags/changeMenuFlag", false);
    },
}
</script>

<style scoped>

</style>
