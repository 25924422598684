<template>
  <div class="second-component-items" ref="scrollable">
    <div class="second-component-container" v-if="items">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="second-component-item"
      >
        <div class="second-component-item__value">
          <h2>
            <CountupComponent
              v-if="isVisible"
              :decimal-places="
                item.value.toString().indexOf('.') != -1
                  ? item.value.split('.')[1].length
                  : 0
              "
              :start-val="0"
              :end-val="item.value"
            />
            {{ item.unit }}
          </h2>
        </div>
        <div class="second-component-item__text">
          <h3>
            {{ item.description }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountupComponent from "@/components/homePage/CountupComponent.vue";

export default {
  name: "SecondComponent",
  components: { CountupComponent },
  props: ["items", "isVisible"],
  data() {
    return {
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.second-component-items {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 100px 0;
  /*border-bottom: 6px solid rgba(71, 77, 147, 0.20);*/
}

.second-component-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  align-items: stretch;
  justify-content: space-between;
}

.second-component-items::-webkit-scrollbar-thumb {
  background-color: rgba(71, 77, 147);
  border-radius: 5px;
}

.second-component-items::-webkit-scrollbar-track {
  background-color: rgba(71, 77, 147, 0.2);
}

.second-component-items::-webkit-scrollbar-button {
  display: none;
}

.second-component-items::-webkit-scrollbar {
  height: 6px;
}

.second-component-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  min-width: 225px;
  margin: 0;
}

.second-component-item__value {
  color: #232429;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.second-component-item__text {
  color: #232429;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1600px) {
  .second-component-item__value {
    font-size: 45px;
  }

  .second-component-items {
    margin: 40px 0;
  }
}

@media (max-width: 1500px) {
  .second-component-item {
    min-width: 200px;
  }
}

@media (max-width: 1300px) {
  .second-component-container {
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .second-component-item {
    padding: 40px 50px;
  }

  .second-component-item__value {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .second-component-item__value {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .second-component-container {
    flex-direction: column;
    overflow-x: hidden;
  }

  .second-component-item__value {
    color: #232429;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .second-component-item {
    padding: 30px 50px 44px;
  }

  .second-component-items::-webkit-scrollbar {
    height: 0;
  }
}
</style>
