<template>
    <div>
        <b-modal id="login"
                 hide-footer
                 hide-header
                 centered
                 hide-backdrop
                 @hide="closeModal"
                 @show="checkUserName"
                 class="login-modal">
            <div class="header-modal">
                <img src="/img/menu-close-w.svg" class="img-close-modal" @click="closeModal"/>
            </div>
            <div class="gsm-2-block">
                <div class="flex flex-column align-items-center">
                    <div class="login-modal-title mb-15 mt-15">Sign In</div>

                    <div class="login-modal-error-block w-100">
                        <div v-if="errors" class="error-color text-center">
                            {{ errors }}
                        </div>
                    </div>

                    <b-form @submit.prevent="submit" class="login-form">
                        <div class="login-form-block">
                            <label>Username *</label>
                            <b-form-input
                                    v-model="form.login"
                                    type="text"
                                    placeholder=""
                                    required
                                    class="gs-2-input1"></b-form-input>
                        </div>
                        <div class="login-form-block">
                            <label>Password *</label>
                            <b-form-input
                                    v-model="form.password"
                                    type="password"
                                    placeholder=""
                                    required
                                    class="gs-2-input1"></b-form-input>
                        </div>

                        <b-form-group class="login-form-radio" v-slot="{ service }">
                            <b-form-radio v-model="form.service" :aria-describedby="service" name="service" :value="1">
                                Client Workspace
                            </b-form-radio>
                            <b-form-radio v-model="form.service" :aria-describedby="service" name="service" :value="2">
                                Data Centre
                            </b-form-radio>
                        </b-form-group>

                        <div class="flex justify-content-between w-100 login-form-checkbox">
                            <b-form-checkbox
                                    id="checkbox-remember"
                                    v-model="form.remember"
                                    name="checkbox-remember"
                                    value="true"
                                    unchecked-value="false">
                                Remember My Username
                            </b-form-checkbox>
                        </div>
                        <div class="spinner-block">
                            <button class="sign-in-btn position-relative spinner-right"
                                    type="submit"
                                    :disabled="loader">
                                Login
                            </button>
                            <b-spinner
                                    v-if="loader"
                                    class="spinner-menu spinner-menu-border spinner-btn"
                                    label="..."></b-spinner>
                        </div>
                    </b-form>
                </div>
            </div>
        </b-modal>
    </div>


</template>

<script>

import axios from "axios";

export default {
    name: "LoginComponent",
    components: {},
    data() {
        return {
            loader: false,
            errors: null,
            form: {
                login: null,
                password: null,
                service: 1,
                remember: true,
                ref: ''
            },
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('login');
            this.form.login = this.form.password = null;
            this.form.service = 1;
            this.form.remember = true;
        },

        checkUserName(){
            if(this.$cookies.get('username')){
                this.form.login = this.$cookies.get('username');
            }
        },

        submit() {
            this.loader = true;

            let formParams = [];

            for (const formProperty in this.form) {
                formParams.push(`${formProperty}=${encodeURI(this.form[formProperty])}`)
            }

            axios.post('/auth', formParams.join('&'), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then(res => {
                if (res.data.status) {
                    if (this.form.remember) {
                        this.$cookies.set('username', this.form.login);
                    }

                    document.location.href = res.data.data.url
                } else {
                    this.errors = res.data.message
                }

                this.loader = false;
            });
        },
    }
}
</script>

<style>
#login .modal-body {
    padding: 0;
    background: #232429;
    border-radius: 2px;
}

.login-modal-error-block {
    color: red;
}

#login .modal-dialog {
    min-width: 1026px;
}

.header-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

.header-modal img {
    cursor: pointer;
    width: 20px;
}

.login-modal-title {
    font-size: 30px;
    color: #fff;
}

.login-form {
    width: 100%;
    padding: 30px 80px;
}

.login-form-block {
    margin-bottom: 20px;
}

.login-form-block input {
    border-radius: initial;
    height: 50px;
}

.login-form-block label {
    color: #fff;
}

.custom-control-label {
    color: #fff;
}

.sign-in-btn {
    display: inline-block;
    padding: 10px 20px;
    text-transform: uppercase;
}

.sign-in-btn:disabled {
    color: #fff;
}

.login-form-radio {
    margin-left: 20px;
}

.login-form-radio .custom-radio {
    margin-top: 10px;
}

.login-form-radio .custom-control-label::after, .login-form-checkbox .custom-control-label::after {
    position: absolute;
    top: 0.26rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.spinner-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.spinner-block .spinner-border {
    color: #fff;
    border-right-color: transparent;
}

@media (max-width: 1100px) {
    #login .modal-dialog {
        min-width: 60%;
    }
}
</style>
