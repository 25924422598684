<template>
  <PageLayout>
    <div class="our-solutions-page site-width">
      <div class="page-title-description">
        <div class="page-title">
          <h2>Our Solutions</h2>
        </div>
        <div class="page-description">
          <p>
            Discover unparalleled insights into your advertising strategies with
            our cutting-edge solutions. Leveraging advanced data analytics, we
            meticulously track and analyze ad data across all media platforms in
            real-time. By harnessing the power of this comprehensive dataset, we
            provide our clients with unparalleled visibility and understanding
            of their advertising performance. Stay ahead of the curve and make
            informed decisions with the industry's best insights, tailored to
            your specific needs. Welcome to a new era of advertising
            intelligence.
          </p>
        </div>
      </div>

      <div class="page-header-image">
        <img src="/img/ourSolutionsPage/our-solutions-page-1.png" alt="" />
      </div>

      <div>
        <div
          v-for="(item, index) in items"
          :key="item.title"
          class="our-solutions-page-item"
          :class="{ 'our-solutions-page-item-reverse': index % 2 === 0 }"
        >
          <div class="our-solutions-page-item__image">
            <img :src="item.image" alt="" />
          </div>
          <div class="our-solutions-page-item-content">
            <div class="our-solutions-page-item-title">
              <h3>{{ item.title }}</h3>
            </div>
            <div class="our-solutions-page-item-text" v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/Layouts/PageLayout.vue";

export default {
  name: "OurSolutionsScreen",
  components: { PageLayout },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Our Solutions - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      items: [
        {
          title: "Integrator",
          text: "<div>Integrate your data to measure marketing attribution and ROI against such key performance indicators (KPI's) as competitive data, sales information, media ratings, brand health metrics and more</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-2.jpg",
        },
        {
          title: "Competitive Data",
          text: "<div>Know your competitors better than they know themselves. Our competitive insights dig deep, revealing more about their strategies than ever before. Get the edge you need to outperform your competitors.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-3.jpg",
        },
        {
          title: "Creative Services",
          text: "<div>Receive immediate updates on the latest advertising campaigns and explore our vast library of millions of ads to gain insights into your competitors’ messaging. Tap into a rich source of creative inspiration, ideal for agencies looking to spark new ideas.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-4.jpg",
        },
        {
          title: "Competitive Pricing",
          text: "<div>Get real-time competitor pricing insights on flyers and e-commerce to adjust your strategy quickly and maintain a competitive edge. Our analysis helps you make informed decisions, ensuring your pricing is always attractive and competitive.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-5.jpg",
        },
        {
          title: "Ad Verify",
          text: "<div>Ad Verify offers enhanced transparency, enabling clients to verify ad placements against purchased schedules. It fosters trust and accountability, bridging the gap between expectations and actual ad performance.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-6.jpg",
        },
        {
          title: "Prospector",
          text: "<div>Uncover new business leads by exploring who advertises what, when, and where. Gain insights into market trends and competitor actions, helping you identify opportunities for growth and collaboration.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-7.jpg",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.page-description {
  color: #232429;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.our-solutions-page-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 250px;
  gap: 100px;
}

.our-solutions-page-item__image {
  width: 50%;
  height: 410px;
  overflow: hidden;
  position: relative;
}

.our-solutions-page-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.our-solutions-page-item-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.our-solutions-page-item-title {
  color: #232429;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.our-solutions-page-item-text {
  color: #232429;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.our-solutions-page-item-text div {
  margin-bottom: 16px;
}

.page-title-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.our-solutions-page .page-title {
  width: 40%;
}

.our-solutions-page .page-description {
  width: 60%;
}

.page-header-image {
  height: 510px;
  overflow: hidden;
  position: relative;
  margin: 80px 0;
}

.page-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.our-solutions-page-item-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 1200px) {
  .our-solutions-page-item {
    margin-bottom: 100px;
  }

  .our-solutions-page-item__image {
    height: 372px;
  }

  .our-solutions-page-item {
    gap: 78px;
  }
}

@media (max-width: 992px) {
  .our-solutions-page-item__image {
    height: 310px;
  }

  .our-solutions-page-item {
    gap: 48px;
  }
}

@media (max-width: 768px) {
  .our-solutions-page .page-description {
    color: #232429;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .our-solutions-page-item-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
  }

  .our-solutions-page-item-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .our-solutions-page .page-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-title-description {
    flex-direction: column;
  }

  .page-header-image {
    height: 348px;
  }

  .our-solutions-page-item {
    margin-bottom: 100px;
    flex-direction: column;
    gap: 40px;
  }

  .our-solutions-page .page-description {
    width: 100%;
  }

  .our-solutions-page-item__image,
  .our-solutions-page-item-content {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .our-solutions-page-item {
    margin-bottom: 40px;
  }

  .our-solutions-page .page-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.28px;
  }

  .page-header-image {
    margin: 40px 0 60px;
  }
}
</style>
