<template>
  <div class="sixth-component">
    <div class="home-component-title">Our Solutions</div>

    <div class="sixth-component-carousel">
      <VueSlickCarousel v-bind="settings" ref="carousel">
        <template #customPaging="page">
          <div
            class="custom-dot"
            :class="'page-' + page"
            @mouseenter="goToSlide(page)"
          ></div>
        </template>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="sixth-component-carousel-block"
        >
          <div
            class="sixth-component-carousel-block__image"
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
          >
            <div
              class="sixth-component-carousel-block__content backdrop-filter-blur"
            >
              <div class="sixth-component-carousel-block__text-block">
                <div class="sixth-component-carousel-block__title">
                  <h3>{{ item.title }}</h3>
                </div>
                <p
                  class="sixth-component-carousel-block__text"
                  v-html="item.text"
                ></p>
              </div>
              <div class="sixth-component-learn-more__btn">
                <AnimatingButton
                  text="Learn more"
                  class="our-solutions-animating-button"
                  @click="openPage(item.url)"
                />
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <!--            <div class="sixth-component-custom-arrow">-->
      <!--                <img src="/img/icons/prev-icon.png" alt="prev" @click="prev">-->
      <!--                <img src="/img/icons/next-icon.png" alt="prev" @click="next">-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import VueSlickCarousel from "vue-slick-carousel";
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "SixthComponent",
  components: {
    AnimatingButton,
    VueSlickCarousel,
  },
  props: ["items"],
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        dotsClass: "custom-dots",
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    };
  },
  methods: {
    openPage(url) {
      this.$router.push(url);
    },

    goToSlide(slideIndex) {
      this.$refs.carousel.goTo(slideIndex);
    },
    prev() {
      this.$refs.carousel.prev();
    },

    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style>
.sixth-component {
  padding: 120px 0;
}

.sixth-component-carousel-block {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sixth-component-carousel-block__title {
  color: #aec6f4;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -2px;
  margin-bottom: 16px;
}

.sixth-component-carousel-block__text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
}

.sixth-component-carousel-block__content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 52px;
  gap: 72px;
}

.sixth-component-carousel-block__image {
  width: 100%;
  height: 920px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

/*.sixth-component-carousel-block__image img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    -o-object-fit: cover;*/
/*    object-fit: cover;*/
/*    position: relative;*/
/*    padding: 0 4px;*/
/*}*/

.sixth-component-carousel {
  position: relative;
}

.sixth-component-custom-arrow {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  bottom: 0;
  left: 0;
}

.sixth-component-custom-arrow img {
  width: 56px;
  height: 56px;
  cursor: pointer;
}

.custom-dots {
  position: absolute;
  width: 100%;
  top: -18px;
  left: 0;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0;
}

.custom-dots li {
  list-style-type: none;
  flex-grow: 1;
  width: 100%;
  min-width: 50px;
  max-width: 255px;
  cursor: pointer;
}

.slick-active .custom-dot {
  background: #aec6f4;
}

.custom-dot {
  height: 9px;
  border-radius: 50px;
  background: #474d9333;
}

.sixth-component-learn-more__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  margin-top: 60px;
  text-transform: uppercase;
  position: absolute;
  right: 52px;
}

.sixth-component-carousel-block__text-block {
  width: 74%;
}

.sixth-component-carousel {
  margin-top: 90px;
}

.our-solutions-animating-button .animating-button {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.2px;
  background: transparent;
  border: 1px solid;
}

.our-solutions-animating-button .animating-button:hover {
  background: transparent;
  color: #fff;
}

@media (max-width: 1600px) {
  .sixth-component {
    padding: 60px 0;
  }

  .sixth-component-carousel {
    margin-top: 60px;
  }

  .sixth-component-carousel-block__image {
    height: 620px;
  }

  .sixth-component-carousel-block__title {
    font-size: 30px;
  }

  .sixth-component-carousel-block__text {
    font-size: 25px;
    line-height: 34px;
  }

  .sixth-component-carousel-block__content {
    padding: 40px 52px;
  }
}

@media (max-width: 1020px) {
  .sixth-component-learn-more__btn {
    position: relative;
    right: 0;
  }
}

@media (max-width: 992px) {
  .sixth-component-carousel-block__text-block {
    width: 100%;
  }

  .sixth-component-carousel-block__content {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px;
    gap: 16px;
  }

  .sixth-component-learn-more__btn {
    width: 100%;
    position: relative;
    right: 0;
  }
}

@media (max-width: 768px) {
  .sixth-component-carousel-block {
    flex-direction: column;
  }

  .sixth-component-carousel-block__image {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .sixth-component-carousel-block__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .sixth-component-learn-more__btn .animating-button-block,
  .sixth-component-learn-more__btn .animating-button {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .sixth-component-carousel-block__image {
    background-image: none !important;
    height: 100%;
    background: #232429;
  }

  .sixth-component-carousel-block__content {
    height: 700px;
  }
}

@media (max-width: 400px) {
  .sixth-component-carousel-block__text {
    font-size: 20px;
  }
}
</style>
