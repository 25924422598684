<template>
    <div class="body min-h-100 overflow-x-hidden page-layout">
        <PageHeaderComponent class="position-relative w-100 mb-40"/>
        <slot></slot>
<!--        <ScheduleDemoComponent/>-->
        <FooterComponent/>
    </div>

</template>

<script>

import FooterComponent from "@/components/FooterComponent.vue";
import PageHeaderComponent from "@/components/PageHeaderComponent.vue";

export default {
    name: "PageLayout",
    components: {PageHeaderComponent, FooterComponent},
    data() {
        return {}
    },
    methods: {},
    mounted() {

    },
}
</script>

<style>


.page-layout .menu-block__item a, .page-layout .menu-block__item button, .page-layout .menu-block__item-div div {
    color: #232429;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}

.page-layout .menu-block__item a:hover, .page-layout .menu-block__item button:hover, .page-layout .menu-block__item-div div:hover {
    text-decoration: none;
    color: #232429;
}

.page-layout .menu-block-items .animating-button {
    color: #232429;
    background: transparent;
    border: 1px solid #232429;
    text-transform: uppercase;
}

.page-layout .menu-block-items a:hover, .page-layout .menu-block-items button:hover, .page-layout .menu-block__item-div div:hover, .page-layout .menu-block-items .router-link-exact-active {
    color: rgb(35, 36, 41) !important;
    text-decoration: none;
    border-bottom: 2px solid rgb(35, 36, 41) !important;
}

.page-layout .solutions-dropdown a:hover {
    color: #000 !important;
    border: none !important;
}

</style>
