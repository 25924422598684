<template>
    <div class="site-width case-study-screen-component-block__circle">
        <div class="case-study-screen-component-block__circle-title">
            {{ title }}
        </div>
        <div class="case-study-screen-component-block__circle-circles">
            <img src="/img/ourSolutionsPage/vector-dashed.svg" alt="vector-dashed" class="vector-dashed">

            <div class="case-study-block-circle">
                <img src="/img/ourSolutionsPage/Frame%201000004611.png" alt="">
                <div class="case-study-block-circle__text">
                    {{ text1 }}
                </div>
            </div>
            <div class="case-study-block-circle">
                <img src="/img/ourSolutionsPage/Frame%201000004612.png" alt="">
                <div class="case-study-block-circle__text">
                    {{ text2 }}
                </div>
            </div>
            <div class="case-study-block-circle">
                <img src="/img/ourSolutionsPage/Frame%201000004613.png" alt="">
                <div class="case-study-block-circle__text text-white">
                    {{ text3 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CaseStudyBlockKPI",
    props: ['title', 'text1', 'text2', 'text3'],
}
</script>

<style>

.case-study-block-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 395px;
    height: 395px;
}

.case-study-block-circle__text {
    max-width: 247px;
    color: #232429;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -1.4px;
    z-index: 1;
}

.case-study-block-circle img {
    width: 395px;
    position: absolute;
    top: 0;
    z-index: 0;
}

.vector-dashed {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 5px;
    z-index: 0;
}

.case-study-screen-component-block__circle-circles {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.case-study-screen-component-block__circle {
    margin-top: 160px;
    margin-bottom: 160px;
}

.case-study-screen-component-block__circle-title {
    color: #232429;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 88px;
    letter-spacing: -2.56px;
    padding: 0 270px;
    margin-bottom: 72px;
}

@media (max-width: 1560px) {
    .case-study-screen-component-block__circle-title {
        padding: 0;
    }
}

@media (max-width: 1300px) {
    .case-study-screen-component-block__circle-title {
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 64px;
        letter-spacing: -1.92px;
    }

    .case-study-block-circle__text {
        max-width: 175px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.2px;
    }

    .case-study-block-circle {
        width: 281px;
        height: 281px;
    }

    .case-study-block-circle img {
        width: 281px;
    }

    .case-study-screen-component-block__circle {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

@media (max-width: 992px) {
    .case-study-screen-component-block__circle-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -1.28px;
        padding: 0 40px;
    }

    .case-study-block-circle__text {
        max-width: 141px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.16px;
    }

    .case-study-block-circle {
        width: 227px;
        height: 227px;
    }

    .case-study-block-circle img {
        width: 227px;
    }

    .vector-dashed {
        position: absolute;
        top: 50%;
        width: 80%;
        left: 20%;
        height: 5px;
        z-index: 0;
    }
}

@media (max-width: 768px) {
    .case-study-screen-component-block__circle {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .case-study-screen-component-block__circle-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -1.28px;
    }

    .case-study-block-circle__text {
        max-width: 114px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
    }

    .case-study-block-circle {
        width: 186px;
        height: 186px;
    }

    .case-study-block-circle img {
        width: 186px;
    }
}

@media (max-width: 600px) {
    .case-study-screen-component-block__circle {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .case-study-screen-component-block__circle-circles {
        flex-direction: column;
    }

    .case-study-screen-component-block__circle-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.64px;
        padding: 0;
        margin-bottom: 40px;
    }

    .case-study-block-circle__text {
        max-width: 167px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.18px;
    }

    .case-study-block-circle {
        width: 269px;
        height: 269px;
        margin-top: 25px;
    }

    .case-study-block-circle img {
        width: 269px;
    }

    .vector-dashed {
        position: absolute;
        transform: rotate(90deg);
        left: -10%;
        top: 50%;
        width: 120%;
        height: 5px;
        z-index: 0;
    }

}

</style>