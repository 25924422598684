<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/verification-case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Verification
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockKPI
        title="Advanced Ad Verification Tool – Invoice Matching Extension"
        text1="Enhanced ad verification, boosting transparency and accountability"
        text2="Campaign monitoring"
        text3="Invoice matching"
      />

      <div class="case-study-screen-component-block__blue">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge Al
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In the dynamic and trust-dependent advertising industry,
                ensuring the accuracy of ad placements is crucial for
                maintaining transparency and building advertiser confidence.
              </div>
              <div>
                This case study outlines an advanced tool designed to enhance
                the ad verification process, the Adease Campaign Manager,
                focusing on its Invoice Matching Extension.
              </div>
              <div>
                This suite of tools aids brands in ensuring that their ad
                campaigns are executed as planned, verifying placements against
                invoices, and fostering an environment of accountability.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/verification-case-study-2.jpg"
        :text="'Key Performance Indicators for <br> Competitive Prospecting'"
      />

      <CaseStudyBlockText
        title="Real-time campaign monitoring"
        :items="items1"
        purpose="This enables immediate adjustments and ensures that campaigns run according to the pre-set criteria, reducing discrepancies and increasing transparency."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Invoice matching accuracy"
        :items="items2"
        purpose="This ensures that billed advertisements align with actual ad placements, enhancing trust and reducing potential billing conflicts."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Discrepancy identification"
        :items="items3"
        purpose="Minimizing discrepancies optimizes advertising spend and enhances the credibility of media placements."
      />

      <CaseStudyBlockText
        title="Detailed performance reporting"
        :items="items4"
        purpose="Compliance monitoring prevents legal issues and maintains high standards of advertising practice, protecting both the advertiser and the broadcaster."
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Value proposition strength"
        :items="items5"
        purpose="Detailed reporting aids in understanding ad performance metrics, guiding strategic decisions and improving future ad engagements."
      />

      <CaseStudyBlockImage
        class="case-study-block-image-mt"
        image="/img/ourSolutionsPage/verification-case-study-3.jpg"
        :text="'Key Performance Indicators for <br> Prospecting Similar Stations'"
      />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt mt-0"
      />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";
import CaseStudyBlockKPI from "@/components/CaseStudyBlockKPI.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "VerificationCaseStudyScreen",
  components: {
    CaseStudyBlockKPI,
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Verification Case Study - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "The Adease Campaign Manager with its Invoice Matching Extension offers a comprehensive solution for real-time verification of advertising campaigns. By employing this advanced tool, media companies can enhance transparency, accountability, and efficiency in their ad operations. Implementing the outlined KPis provides a robust framework for evaluating and improving the effectiveness of the ad verification process, ultimately leading to increased advertiser confidence and a stronger market position.",
      items1: [
        {
          title: "Track Ad Placement Accuracy",
          text: "Monitor ad placements in real-time to verify accuracy against the planned media schedule.",
        },
        {
          title: "Analyze Real-Time Data",
          text: "Utilize the data gathered to assess the timeliness and precision of ad placements.",
        },
      ],

      items2: [
        {
          title: "Verify Invoice Matching",
          text: "Use advanced algorithms to match ads with corresponding invoice records.",
        },
        {
          title: "Assess Matching Accuracy",
          text: "Evaluate the effectiveness of the matching process and identify any discrepancies.",
        },
      ],
      items3: [
        {
          title: "Detect and Report Discrepancies",
          text: "Identify any deviations from the planned ad schedule and recorded invoices.",
        },
        {
          title: "Resolve Discrepancies",
          text: "Implement mechanisms to quickly address and rectify identified discrepancies.",
        },
      ],
      items4: [
        {
          title: "Monitor Ad Compliance",
          text: "Ensure that all ads adhere to relevant regulations and guidelines.",
        },
        {
          title: "Evaluate Compliance Reports",
          text: "Regularly review compliance status and take necessary actions to maintain standards.",
        },
      ],
      items5: [
        {
          title: "Generate Detailed Reports",
          text: "Provide comprehensive insights on ad performance and verification status.",
        },
        {
          title: "Utilize Reports for Strategy Development",
          text: "Use the detailed analytics to inform future advertising strategies and optimize placements.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
