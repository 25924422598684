<template>
    <div>
        <ICountUp
            :startVal="startVal"
            :endVal="endVal"
            :options="options"
            @ready="onReady"
        />
        <slot></slot>
    </div>
</template>

<script type="text/babel">
import ICountUp from 'vue-countup-v2';

export default {
    name: 'CountupComponent',
    components: {
        ICountUp
    },
    props: ['startVal', 'endVal', 'decimalPlaces'],
    data() {
        return {
            options: {
                useEasing: true,
                useGrouping: true,
                duration: 5,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                decimalPlaces: this.decimalPlaces,
            }
        };
    },
    mounted() {},
    methods: {
        onReady: function(instance) {
            const that = this;
            instance.update(that.endVal);
        }
    }
};
</script>

<style>
</style>
