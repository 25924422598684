<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/creative-case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Navigating the Ad Landscape: Strategic Insights from Radio and TV
              Creative Data
            </div>
          </div>
        </div>
      </div>

      <div class="case-study-screen-component-block__blue background-white">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge AI
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In an era where multimedia advertising is crucial for brand
                success, understanding how to strategically deploy radio and TV
                ads can significantly impact market presence and consumer
                engagement.
              </div>
              <div>
                This case study delves into how companies can harness the power
                of radio and TV creative data to refine their advertising
                strategies, monitor competitor activities, and enhance brand
                messaging.
              </div>
              <div>
                By leveraging this data, brands can adapt and innovate their
                approaches to not only match but surpass their competition in
                the dynamic advertising arena.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/creative-case-study-2.jpg"
        :text="'Key Performance Indicators for <br> Competitive Analysis'"
      />

      <CaseStudyBlockText title="Competitor Analysis" :items="items1" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Brand Messaging Analysis" :items="items2" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText title="Offer Promotion Flagging" :items="items3" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="New Product Promotion Detection"
        :items="items4"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Creative Lifecycle Management"
        :items="items5"
      />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt"
      />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "CompetitiveBrandsCaseStudyScreen",
  components: {
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Creative Case Study - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "Employing radio and TV creative data provides brands with a granular view of the advertising landscape, essential for making informed strategic decisions. This access to competitor insights and market dynamics allows brands to proactively adjust and optimize their advertising efforts, ensuring they not only meet but exceed consumer expectations and remain competitive in the bustling market.",
      items1: [
        {
          title: "Objective",
          text: "Analyze the frequency and content of competitor advertisements to refine strategic positioning and messaging.",
        },
        {
          title: "Outcome",
          text: "Company A utilized radio and TV creative data to assess the most advertised products and services by their competitors. Insights gathered allowed them to adjust their own advertising campaigns, leading to a 25% increase in brand recall and a 20% growth in market share by aligning their ads more closely with consumer expectations and gaps left by competitors.",
        },
      ],
      items2: [
        {
          title: "Objective",
          text: "Understand the prevailing messaging trends used by competitors to enhance the persuasive power of your own ads.",
        },
        {
          title: "Outcome",
          text: "Company A analyzed the key messages conveyed in competitors' ads, adopting best practices and avoiding over-saturated themes. This strategy led to a 15% improvement in message effectiveness and a 22% increase in consumer response rates.",
        },
      ],
      items3: [
        {
          title: "Objective",
          text: "Identify and respond to competitors' promotional strategies to optimize your own promotional campaigns.",
        },
        {
          title: "Outcome",
          text: "By tracking special offers and discounts advertised by competitors, Company A was able to launch timely counter-promotions, resulting in a 30% uptick in sales during the promotional periods.",
        },
      ],
      items4: [
        {
          title: "Objective",
          text: "Quickly identify and understand the advertising strategies surrounding new product launches by competitors.",
        },
        {
          title: "Outcome",
          text: "Company A effectively spotted and analyzed new product advertisements by competitors, enabling them to launch pre-emptive marketing strategies for similar products, which increased their new product uptake by 40%.",
        },
      ],
      items5: [
        {
          title: "Objective",
          text: "Distinguish between new and ongoing creatives to optimize the lifecycle of your advertising content.",
        },
        {
          title: "Outcome",
          text: "Through meticulous tracking and analysis, Company A managed their creative assets more efficiently, retiring outdated ads and pushing successful ones longer, which enhanced overall ad performance by 50%.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
