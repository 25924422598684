<template>
    <div class="case-study-screen-component-conclusion">
        <div class="case-study-screen-component-conclusion__title">
            Conclusion
        </div>
        <div v-if="text" class="case-study-screen-component-conclusion__text">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: "CaseStudyBlockConclusion",
    props: ['text'],
}
</script>

<style>


.case-study-screen-component-conclusion {
    background: #AEC6F4;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.case-study-screen-component-conclusion__title {
    color: #232429;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -2.08px;
    margin-bottom: 24px;
}

.case-study-screen-component-conclusion__text {
    color: #232429;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.64px;
    width: 1160px;
}

@media screen and (max-width: 1300px) {

    .case-study-screen-component-conclusion__title {
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 64px;
        letter-spacing: -1.92px;
    }

    .case-study-screen-component-conclusion__text {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.56px;
        width: 100%;
        padding: 0 60px;
    }
}

@media screen and (max-width: 992px) {
    .case-study-screen-component-conclusion__title {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -1.28px;
    }

    .case-study-screen-component-conclusion__text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 500px) {

    .case-study-screen-component-conclusion__text {
        padding: 0;
    }
}

</style>