<template>
  <div class="animating-text-component">
    <div class="site-width" ref="siteWidth">&nbsp;</div>

    <div class="first-component-block backdrop-filter-blur">
      <div
        class="first-component-text"
        ref="firstComponentText"
        :class="isAnimating ? 'animated' : ''"
      >
        <h1>
          <span
            v-for="(word, index) in words"
            :key="index"
            class="word-transition"
            :class="currentIndex >= index ? 'white' : 'transparent'"
          >
            {{ word }}
          </span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimatingTextComponent",
  props: ["text"],
  data() {
    return {
      words: [],
      currentIndex: 0,
      intervalId: null,
      isAnimating: false,
      timeout: 500,
      innerWidth: window.innerWidth,
    };
  },
  mounted() {
    this.changeInnerWidth();
    this.startAnimating();
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    changeInnerWidth() {
      let siteWidth = this.$refs.siteWidth;
      if (this.innerWidth > 1200) {
        this.$refs.firstComponentText.style.marginLeft =
          siteWidth.offsetLeft + "px";
      } else {
        this.$refs.firstComponentText.style.marginLeft = "80px";
      }
    },
    startAnimating() {
      this.words = this.text.split(" ");
      this.intervalId = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex >= this.words.length) {
          // this.currentIndex = -1;
          this.timeout = 500;
          this.isAnimating = false;
        } else {
          this.isAnimating = true;
          this.timeout -= 20;
          clearInterval(this.intervalId);
          this.startAnimating();
        }
      }, this.timeout);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
    isAnimating: function () {
      if (!this.isAnimating) {
        clearInterval(this.intervalId);
        setTimeout(() => {
          this.startAnimating();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
.first-component-block {
  position: relative;
  width: 60%;
}

.first-component-text {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  column-gap: 30px;
  color: #fff;
  transition: 1s;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -2.56px;
  padding: 52px 52px 52px 0;
  h1 {
    flex-wrap: wrap;
    column-gap: 30px;
  }
}

.first-component-text.animated {
  animation-name: slideUp;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.first-component-text.off-animated {
  animation-name: slideUpOff;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  from {
    bottom: -190px;
  }
  to {
    bottom: 0;
  }
}

@keyframes slideUpOff {
  from {
    bottom: 0;
  }
  to {
    bottom: -190px;
  }
}

.word-transition {
  transition: color 1s ease;
}

@media (max-width: 1300px) {
}

@media (max-width: 992px) {
  .first-component-block {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .first-component-text {
    padding: 31px;
    margin-left: 0 !important;
    font-size: 40px;
    line-height: normal;
    letter-spacing: normal;
  }
}

@media (max-width: 576px) {
  .first-component-text {
  }
}
</style>
