<template>
  <div
    class="contact-us-component"
    style="background-image: url('/img/contact-us.png')"
  >
    <div class="inner">
      <div class="home-component-title" ref="formContactTitle">Contact Us</div>
      <div class="form-contact-component-blocks">
        <b-form
          @submit.prevent="sendData"
          class="form-contact-component"
          ref="formContactComponent"
        >
          <div class="form-contact-component-block">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                placeholder="Name *"
                required
                autocomplete="off"
              />
            </b-form-group>
          </div>

          <div class="form-contact-component-block">
            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                placeholder="Email *"
                required
                autocomplete="off"
              />
            </b-form-group>
          </div>

          <div class="form-contact-component-block">
            <b-form-group>
              <b-form-input
                  v-model="form.subject"
                  type="text"
                  placeholder="Subject *"
                  required
                  autocomplete="off"
              />
            </b-form-group>
          </div>

          <div class="form-contact-component-block">
            <b-form-group>
              <b-form-textarea
                id="textarea"
                placeholder="Message *"
                rows="5"
                max-rows="8"
                v-model="form.message"
                required
              ></b-form-textarea>
            </b-form-group>
          </div>

          <AnimatingBTN
            class="form-contact-component-btn"
            text="Send Message"
          />
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import methods from "@/Includes/util";
import AnimatingBTN from "@/components/homePage/AnimatingBTN.vue";

export default {
  name: "ContactUsComponent",
  components: { AnimatingBTN },
  data() {
    return {
      loader: false,
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      innerWidth: window.innerWidth,
    };
  },
  methods: {
    resetForm() {
      this.form = {
        name: "",
        email: "",
        subject: "",
        message: "",
      };
    },
    sendData() {
      this.loader = true;

      axios
        .post("https://media.adeasemediaresearch.com/index/contact", this.form)
        .then((res) => {
          if (res.data.status) {
            this.resetForm();

            methods.makeSuccessToast(this, res.data.message);
          } else {
            methods.makeErrorToast(this, res.data.message);
          }

          this.loader = false;
        })
        .catch((error) => {
          console.error(error);

          this.loader = false;
        });
    },
    changeInnerWidth() {
      let formContactComponent = this.$refs.formContactComponent;
      if (this.innerWidth > 1300) {
        this.$refs.formContactTitle.style.marginLeft =
          formContactComponent.offsetLeft + "px";
      } else if (this.innerWidth < 1300 && this.innerWidth > 1090) {
        this.$refs.formContactTitle.style.marginLeft = "100px";
      } else if (this.innerWidth < 1090 && this.innerWidth > 752) {
        this.$refs.formContactTitle.style.marginLeft = "5%";
      } else {
        this.$refs.formContactTitle.style.marginLeft = "0px";
      }
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.changeInnerWidth();
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
  },
};
</script>

<style lang="scss">
.form-contact-component-btn {
  margin-top: 101px;
}

.contact-us-component {
  .inner {
    padding: 0 24px;
  }
}

.form-contact-component-btn .animating-button {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.15);
  text-transform: uppercase;
  width: 100%;
}

.form-contact-component-btn .animating-button:hover {
  border: 1px solid rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.15);
}

.contact-us-component .home-component-title {
  color: #fff;
  margin-bottom: 120px;
  margin-left: 30px;
}

.form-contact-component-block textarea {
  // height: 44px !important;
  overflow-y: auto !important;
}

.form-contact-component-block input,
.form-contact-component-block textarea {
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.form-contact-component-block input:focus,
.form-contact-component-block textarea:focus {
  background: transparent;
  box-shadow: none;
}

.form-contact-component-block input::placeholder,
.form-contact-component-block textarea::placeholder {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.form-contact-component-block {
  margin-bottom: 64px;
}

.form-contact-component-blocks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-component {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 80px 0;
  position: relative;
  margin-top: 120px;
  margin-bottom: 160px;
  // height: 906px;
}

.form-contact-component {
  max-width: 752px;
  width: 100%;
}

@media (max-width: 1600px) {
  .contact-us-component {
    padding: 40px 0;
    margin-top: 60px;
    margin-bottom: 60px;
    // height: 700px;
  }
}
</style>
