
let methods = {

    makeToast(t, variant, str, str2, delay = 4000) {
        t.$bvToast.toast(str, {
            title: str2,
            variant: variant,
            solid: true,
            autoHideDelay: delay,
        })
    },

    makeSuccessToast(component, message) {
        this.makeToast(component, 'success', message, 'Message')
    },

    makeErrorToast(component, message) {
        this.makeToast(component, 'danger', message, 'Message')
    },

    rand() {
        return Math.random().toString().replace('.', '');
    },

    isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }
}

export default methods;
