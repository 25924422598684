<template>
    <b-modal
            id="show-file"
            content-class="show-file-modal"
            hide-footer
            @hide="hideModal">

        <template #modal-header>
            <div class="flex flex-wrap align-items-center justify-content-between w-100">
                <div class="show-file-modal__title">{{ modalName }}</div>
                <div class="show-file-modal__date">
                    {{ headerDate }}

                    <b-icon icon="x-lg" @click="$bvModal.hide('show-file')" class="arrow-right-animating"/>
                </div>
            </div>
        </template>

        <div v-if="typeFile === 'video'" class="show-file-modal__content">
            <video class="video-modal" autoplay controls>
                <source :src="source" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>

        <div v-if="typeFile === 'audio'" class="show-file-modal__content">
            <video class="video-modal radio-default-video" :ref="'videoPlayer'" autoplay loop @click="toggleAudio">
                <source src="/img/radio-default-video.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="audio-modal-block">
                <audio class="audio-modal" autoplay controls :ref="'audioPlayer'"
                       @pause="pauseVideoAudio"
                       @play="playVideoAudio">
                    <source :src="source" type="audio/mp3">
                    Your browser does not support the audio tag.
                </audio>
            </div>
        </div>

        <div class="modal-charts-block">

            <div class="modal-charts-block-check-data" v-if="!loader">
                <div @click="flagData = false" :class="!flagData ? 'active-slider-data' : ''">
                    Last Week
                </div>
                <div @click="flagData = true" :class="flagData ? 'active-slider-data' : ''">
                    Full Campaign
                </div>
            </div>

            <SliderComponent :items="data"/>


            <HighchartsModal v-if="chartData?.mediaActivity?.length" :data="chartData"/>
            <div v-if="!loader && !chartData?.mediaActivity?.length" class="no-results-text mb-40 mt-10">
                No Results Activity
            </div>

            <div class="spinner-table" v-if="loader">
                <b-spinner class="spinner-table-border ml-10" label="..."></b-spinner>
            </div>
        </div>

        <div v-if="loader" class="mb-40 mt-10"></div>

    </b-modal>
</template>

<script>
import SliderComponent from "./SliderComponent.vue";
import HighchartsModal from "./HighchartsModal.vue";

export default {
    name: "AdShowModal",
    props: ['item'],
    emits: ['hide'],
    components: {
        HighchartsModal,
        SliderComponent
    },
    data() {
        return {
            data: [],
            chartData: null,
            loader: false,
            campaign: [],
            period: [],
            headerDate: '',
            flagData: false,
            source: null,
            typeFile: 'show-file',
            modalName: null,
        }
    },
    methods: {
        hideModal() {
            this.data = this.period = this.campaign = [];
            this.flagData = false;
            this.chartData = null;
            this.$emit('hide');
        },

        playVideoAudio() {
            const video = this.$refs['videoPlayer'];
            video.play();
        },

        pauseVideoAudio() {
            const video = this.$refs['videoPlayer'];
            video.pause();
        },

        toggleAudio() {
            const audio = this.$refs['audioPlayer'];
            const video = this.$refs['videoPlayer'];

            if (audio.paused) {
                audio.play();
                video.play();
            } else {
                audio.pause();
                video.pause();
            }
        },

        getAds() {
            if (this.item.url) {
                const fileExtension = this.item.url.split('.').pop().toLowerCase();
                this.source = this.item.url;
                this.modalName = this.item.title;
                this.typeFile = fileExtension === 'mp4' ? 'video' : 'audio';
                this.headerDate = this.item?.startDate + ' - ' + this.item?.endDate;

                this.loader = true;
                setTimeout(() => {
                    this.chartData = this.item;
                    this.period = this.item.stats?.period;
                    this.campaign = this.item?.stats?.campaign;

                    this.data = this.period;
                    this.loader = false
                }, 500);


            }
        }
    },
    watch: {
        flagData() {
            this.data = [];
            setTimeout(() => {
                this.data = this.flagData ? this.campaign : this.period;
            }, 200);
        },

        item() {
            if (this.item)
                this.getAds();
        }
    },
}
</script>

<style>
#show-file .arrow-right-animating {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.modal-charts-block-check-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 0 -3px 0 0 #CFD4DA inset;
    gap: 10px;
}

.modal-charts-block-check-data div {
    cursor: pointer;
    padding: 6px 20px 14px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #9E9E9E;
    box-shadow: 0 -3px 0 0 #CFD4DA inset;
    transition: .3s;
}

.modal-charts-block-check-data .active-slider-data {
    color: #1B1D1C;
    box-shadow: 0 -3px 0 0 #064885 inset;
}

.show-file-modal__date {
    color: #4D4D4D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 35px;
}

.show-file-modal__title {
    color: #1B1D1C;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.show-file-modal__content {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.video-modal {
    width: 100%;
    height: 100%;
}

.audio-modal {
    width: 100%;
}

#show-file .modal-dialog {
    max-width: 50%;
}

#show-file .modal-content {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    border: none;
}

#show-file .modal-body {
    padding: 0 30px;
}

#show-file .modal-header {
    border: none;
    padding: 16px 30px;
}

#show-file .modal-title {
    color: #1B1D1C;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#show-file .spinner-table {

}

#show-file .audio-modal-block {
    position: absolute;
    width: 100%;
    bottom: 0;
}

#show-file .audio-modal {
    border-radius: 0 !important;
}

.modal-charts-block {
    position: relative;
    min-height: 200px;
}

.highcharts-legend {
    visibility: hidden;
}

@media (max-width: 768px) {
    #show-file .modal-dialog {
        max-width: 80%;
    }
}

@media (max-width: 580px) {
    #show-file .modal-dialog {
        max-width: 100%;
    }
}

</style>
