<template>
  <div class="tenth-component">
    <img
      class="pic-component-bg"
      ref="picComponentBg"
      src="/img/tenth-component-bg1.png"
      alt=""
    />
    <img
      class="pic-component-bg2"
      ref="picComponentBg2"
      src="/img/tenth-component-bg1200.png"
      alt=""
    />
    <img
      class="pic-component-bg3"
      ref="picComponentBg3"
      src="/img/tenth-component-bg992.png"
      alt=""
    />

    <div class="tenth-component-blocks site-width">
      <img
        class="small-component-bg"
        src="/img/tenth-component-bg2.png"
        alt=""
      />
      <div class="tenth-component-block2">
        <img
          class="tenth-component-block2__logo"
          src="/img/aurbit-logo.png"
          alt=""
        />
        <!--                <div class="tenth-component-block2__title">-->
        <!--                    Welcome to aurbit.-->
        <!--                </div>-->
        <div class="tenth-component-block2__text">
          <p>
            Transform TV and radio ads with aurbit: Dynamic, audio-driven
            interactions replace QR codes. Engage seamlessly across mediums,
            gaining insights for impactful strategies. It's the future of
            advertising attribution.
          </p>
        </div>

        <AnimatingButton
          text="Learn more"
          class="learn-more-animating-button"
          @click="openPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "TenthComponent",
  components: { AnimatingButton },
  props: ["innerWidth"],
  data() {
    return {
      maxOffset: 800,
    };
  },
  methods: {
    openPage() {
      window.open("https://aurbit.ai", "_blank");
    },
    changeInnerWidth() {
      if (this.innerWidth < 1920) {
        const baseWidth = 1920;
        const widthDifference = baseWidth - this.innerWidth;

        if (this.innerWidth < 1300) {
          this.maxOffset = 600;
        }

        if (this.innerWidth < 992) {
          this.maxOffset = 600;
        }

        const offset = (widthDifference / baseWidth) * this.maxOffset + 60;
        console.log("offset", offset);

        this.$refs.picComponentBg.style.left = "-" + offset + "px";
        this.$refs.picComponentBg2.style.left = "-" + offset + "px";
        this.$refs.picComponentBg3.style.left = "-" + (offset + 30) + "px";
      } else {
        this.$refs.picComponentBg.style.left = "-60px";
      }
    },
  },
  mounted() {
    this.changeInnerWidth();
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
  },
};
</script>

<style lang="scss">
.ninth-component-block1 {
  @media screen and (max-width: 992px) and (min-width: 768px) {
    margin-bottom: 250px;
  }
}

.tenth-component {
  position: relative;
  margin-top: 97px;
  min-height: 920px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tenth-component .pic-component-bg {
  position: absolute;
  left: -3vw;
  top: 0;
  height: 1000px;
  z-index: 0;
}

.tenth-component .pic-component-bg2,
.tenth-component .pic-component-bg3 {
  display: none;
  position: absolute;
  left: -3vw;
  top: -108px;
  height: 1000px;
  z-index: 0;
}

.tenth-component-blocks {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;
}

.tenth-component-block2__title {
  color: #232429;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}

.tenth-component-block2__text {
  color: #666;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
  margin-top: 32px;
}

.tenth-component-block2 {
  padding: 0 0 0 112px;
  width: 684px;
}

.tenth-component-block2__logo {
  width: 212px;
}

.tenth-component .learn-more-animating-button {
  margin-top: 60px;
  width: 240px;
  text-transform: uppercase;
}

@media (max-width: 1600px) {
  .tenth-component .pic-component-bg {
    height: 800px;
  }

  .tenth-component-block2__text {
    font-size: 25px;
    line-height: 40px;
  }

  .tenth-component {
    min-height: 780px;
  }
}

@media (max-width: 1300px) {
  .tenth-component-block2 {
    width: 50%;
  }

  .tenth-component {
    margin-top: 0;
  }

  .tenth-component .pic-component-bg {
    display: none !important;
  }

  .tenth-component .pic-component-bg2 {
    display: block;
  }
}

@media (max-width: 992px) {
  .tenth-component-block2 {
    width: 56%;
  }

  .tenth-component-block2__logo {
    width: 164px;
  }

  .tenth-component-block2__title,
  .tenth-component .learn-more-animating-button {
    margin-top: 39px;
  }

  .tenth-component .pic-component-bg2 {
    display: none !important;
  }

  .tenth-component .pic-component-bg3 {
    display: block;
  }

  .tenth-component-block2__text {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .tenth-component-block2__text {
    line-height: normal;
    font-size: 32px;
  }

  .tenth-component .learn-more-animating-button,
  .tenth-component .learn-more-animating-button .animating-button-black {
    width: 100% !important;
  }

  .tenth-component-block2__title,
  .tenth-component-block2__text,
  .tenth-component .learn-more-animating-button {
    margin-top: 16px;
  }

  .tenth-component-block2__text {
    line-height: normal;
  }
}

@media (max-width: 767px) {
  .tenth-component .pic-component-bg,
  .tenth-component .pic-component-bg2,
  .tenth-component .pic-component-bg3 {
    display: none;
  }

  .tenth-component-block2__logo {
    width: 94px;
  }

  .tenth-component-blocks {
    flex-direction: column;
  }

  .tenth-component .small-component-bg {
    order: 2;
    padding: 22px;
  }

  .tenth-component-block2 {
    width: 100%;
    padding: 0;
    order: 1;
  }

  .tenth-component .learn-more-animating-button,
  .tenth-component .animating-button {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .tenth-component-block2__logo {
    width: 94px;
  }
}
</style>
