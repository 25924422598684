<template>
  <div class="fourth-component">
    <div class="home-component-title site-width">
      <h2>Top Campaign in Canada</h2>
    </div>
    <div class="home-component-text site-width">
      <span>{{ period }}</span>
    </div>

    <div class="fourth-component-items-container">
      <div class="fourth-component-items site-width">
        <div
          v-for="(ad, index) in ads"
          :key="index"
          class="fourth-component-item"
        >
          <div v-if="ad.type === 'video'">
            <div class="fourth-component-item-img" @click="showModal(ad)">
              <img class="w-100 d-block" :src="ad.poster" :alt="ad.text" />
              <img
                class="play-ico"
                src="/img/play.png"
                alt="play"
                v-if="ad.poster"
              />
            </div>
          </div>

          <div v-if="ad.type === 'audio'" class="position-relative">
            <div class="fourth-component-item-img" @click="showModal(ad)">
              <img
                class="w-100 d-block"
                src="/img/fifth-component2.png"
                :alt="ad.text"
              />
              <img class="play-ico" src="/img/play.png" alt="play" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShowVideoModal :item="item" @hide="closeModal" />
  </div>
</template>

<script>
import ShowVideoModal from "@/components/modal/AdShowModal.vue";

export default {
  name: "FourthComponent",
  props: ["ads", "period"],
  components: { ShowVideoModal },
  data() {
    return {
      isPlayingVideo: false,
      isPlayingAudio: false,
      item: {},
    };
  },
  methods: {
    closeModal() {
      this.item = {};
    },

    showModal(data) {
      if (data.url) {
        this.item = data;

        setTimeout(() => {
          this.$bvModal.show("show-file");
        }, 200);
      }
    },
  },
};
</script>

<style>
.fourth-component-item-img {
  height: 580px;
  overflow: hidden;
  position: relative;
}

.fourth-component-item-img .d-block {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.fourth-component {
  /* padding: 120px 0; */
  padding-bottom: 120px;
}

.fourth-component .home-component-text {
  margin-bottom: 80px;
  margin-top: 24px;
  color: #828282;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
}

.fourth-component-items {
  display: flex;
  gap: 10px;
}

.fourth-component-item {
  width: 50%;
  cursor: pointer;
}

.fourth-component-item img {
  cursor: pointer;
}

.fourth-component-item video {
  width: 100%;
}

.fourth-component-item audio {
  position: absolute;
  bottom: 6px;
  width: 100%;
  background: transparent;
}

.play-ico {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 17%;
}

@media (max-width: 1600px) {
  .fourth-component {
    padding-bottom: 60px 0;
  }

  .fourth-component .home-component-text {
    margin-bottom: 40px;
    margin-top: 15px;
    font-size: 25px;
  }
  .fourth-component-item-img {
    height: 500px;
  }
}

@media (max-width: 992px) {
  .fourth-component-items {
    flex-direction: column;
  }

  .fourth-component-item {
    width: 100%;
    position: relative;
  }

  .fourth-component {
    padding-bottom: 100px 0;
  }
}

@media (max-width: 567px) {
  .fourth-component-item-img {
    height: 300px;
  }

  .fourth-component-items {
    flex-direction: column;
  }
}

@media (max-width: 467px) {
  .fourth-component-items-container .site-width {
    /* padding: 0; */
  }
}
</style>
