<template>
    <div class="case-study-block-image"
         :style="{backgroundImage: 'url(' + image + ')'}"
         v-html="text">
    </div>
</template>

<script>
export default {
    name: "CaseStudyBlockImage",
    props: ['text', 'image']
}
</script>

<style>
.case-study-block-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 757px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 88px;
    letter-spacing: -2.56px;
}

@media (max-width: 1300px) {
    .case-study-block-image {
        height: 620px;
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 80px;
        letter-spacing: -2.56px;
    }
}

@media (max-width: 992px) {
    .case-study-block-image {
        height: 620px;
        font-size: 52px;
        font-style: normal;
        font-weight: 500;
        line-height: 68px;
        letter-spacing: -2.08px;
    }
}

@media (max-width: 768px) {
    .case-study-block-image {
        height: 540px;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: -1.6px;
    }
}

@media (max-width: 500px) {
    .case-study-block-image {
        height: 450px;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -1.6px;
    }
}


</style>