<template>
    <div class="footer-component">
        <div class="site-width">
            <div class="footer-component-blocks">
                <img class="footer-component-logo" src="/img/logo.png" alt="">
                <div class="footer-component-third footer-component-third-big">
                    <a href="https://ca.linkedin.com/company/adease-media-intelligence" target="_blank">
                        <img src="/img/icons/LD.png" alt="linkedin">
                    </a>
                </div>
            </div>

            <div class="footer-component-temp__text">
                <span>All contents © 2024 Adease Media Research Inc.</span> <span> All rights reserved.</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style scoped>
.footer-component-blocks {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
}

.footer-component-icon img{
    width: 60px;
}

.footer-component-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.footer-component {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #232429;
    color: #fff;
}

.footer-component-temp__text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.footer-component-logo {
    width: 122px;
}

.footer-component-third a {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-component-third img{
    width: 60px;
}

.footer-component-third a:hover {
    text-decoration: none;
    color: #fff;
}

@media (max-width: 768px) {

    .footer-component-blocks {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .footer-component-temp__text{
        margin-bottom: 50px;
        text-align: center;
    }
}

</style>