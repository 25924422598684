<template>
  <div class="w-100 mainHeader">
    <div class="header d-flex justify-content-between align-items-center">
      <router-link to="/" class="logo-img">
        <img
          v-if="!$store.getters['Flags/getMenuFlag']"
          src="/img/Logo.svg"
          alt=""
        />
        <img v-else src="/img/logo-black.png" alt="" />
      </router-link>
      <div class="d-flex align-items-center justify-content-end">
        <div class="menu-block">
          <MainMenuComponent />
        </div>
        <div class="menu-burger-block">
          <input type="checkbox" id="myInput" ref="myInput" />
          <label for="myInput" @click="openMenu" class="menu-burger">
            <img src="/img/menu.png" class="menu-img" alt="" />
          </label>

          <BurgerMenuComponent />

          <label for="myInput" @click="openMenu" class="menu-burger-close">
            <img src="/img/menu-close.png" class="menu-img" alt="" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenuComponent from "@/components/MainMenuComponent.vue";
import BurgerMenuComponent from "@/components/BurgerMenuComponent.vue";

export default {
  name: "HeaderComponent",
  props: [],
  components: { BurgerMenuComponent, MainMenuComponent },
  data() {
    return {};
  },

  mounted() {
    window.addEventListener("scroll", () => {
      let header = document.querySelector(".mainHeader");
      header.classList.toggle("active", window.scrollY > 0);
    });
  },
  methods: {
    openMenu() {
      let flag = this.$store.getters["Flags/getMenuFlag"];
      this.$store.dispatch("Flags/changeMenuFlag", !flag);

      if (!flag) {
        document.body.classList.add("menu-open");
        document.documentElement.classList.add("menu-open");
      } else {
        document.body.classList.remove("menu-open");

        document.documentElement.classList.remove("menu-open");
      }
    },
  },
};
</script>

<style lang="scss">
.mainHeader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: 0.2s all ease-in-out;
  &.active {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    .menu-burger {
      img {
        filter: invert(1);
      }
    }
    .logo-img {
      img {
        filter: invert(1);
      }
    }
    .animating-button {
      border: 1px solid #202e49;
      &:hover {
        background: #eee !important;
      }
    }
    a,
    button {
      &:hover {
        color: #202e49 !important;
      }
    }
    * {
      color: #202e49;
    }
  }
}

body,
html {
  &.menu-open {
    overflow: hidden;
  }
}

.header {
  padding: 20px 0 !important;
  margin: 0 auto;
  max-width: 1520px;
}

.logo-img {
  z-index: 1;
}

.logo-img img {
  width: 120px;
}

.btn-login {
  background: #fff;
  width: 160px;
  height: 56px;
  color: rgb(35, 36, 41);
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  cursor: pointer;
}

.btn-login:hover {
  text-decoration: none;
  color: rgb(35, 36, 41);
}

.menu-img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.active-menu-btn {
  background: #232429;
  color: #fff;
}

.active-menu-btn:hover {
  text-decoration: none;
  color: #fff;
}

.menu-burger-block {
  z-index: 5;
  margin-left: 40px;
  display: none;
}

@media (max-width: 1300px) {
  .header {
    padding: 20px 65px !important;
  }
}

@media (max-width: 992px) {
  .menu-block {
    display: none !important;
  }

  .menu-burger-block {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 31px !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .header {
    padding: 18px 24px !important;
  }

  .menu-img {
    width: 24px;
    height: 24px;
  }

  .logo-img img {
    width: 74px !important;
  }
}

@media (max-width: 576px) {
  .header {
    padding: 17px 16px !important;
  }

  .logo-img img {
    width: 52px !important;
  }
}
</style>
