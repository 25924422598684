<template>
    <div>
        <div class="modal-charts-block-data" v-if="items.length">
            <img src="/img/icons/back-circle.svg" @click="$refs.prev.click()"
                 alt="back-circle"
                 class="rotate-180 back-circle-prev">
            <img src="/img/icons/back-circle.svg" @click="$refs.next.click()"
                 class="back-circle-next"
                 alt="back-circle">
            <div>
                <vue-owl-carousel
                        ref="carousel"
                        :nav="false"
                        :dots="false"
                        :items="4"
                        :responsive="{0:{items:1},600:{items:2},1000:{items:3},1200:{items:4}}"
                        :margin="10">
                    <div v-for="(item, index) in items" :key="index" class="modal-charts-block-data__item">
                        <div :class="item.value.length > 10
                                ?'modal-charts-block-data__item-title-date'
                                :'modal-charts-block-data__item-title'">
                            {{ item.value }}
                        </div>
                        <div class="modal-charts-block-data__item-percent-text">{{ item.description }}</div>
                    </div>

                    <template slot="prev"><span hidden ref="prev" class="prev">prev</span></template>
                    <template slot="next"><span hidden ref="next" class="next">next</span></template>
                </vue-owl-carousel>
            </div>
        </div>
        <div v-else class="h-114"></div>
    </div>
</template>

<script>

export default {
    name: "SliderComponent",
    components: {},
    props: ['items'],
    data() {
        return {
            // items: [],
        };
    },
    methods: {},
};
</script>

<style>
.back-circle-next, .back-circle-prev {
    cursor: pointer;
    position: absolute;
    top: 36%;
    width: 28px;
}

.back-circle-prev {
    left: 0;
}

.back-circle-next {
    right: 0;
}

.modal-charts-block-data .owl-carousel .owl-stage {
    display: flex;
    align-items: stretch;
}

.owl-carousel .owl-stage {
    display: flex;
    align-items: stretch;
}

.modal-charts-block-data {
    position: relative;
    padding: 0 38px;
}

.modal-charts-block-data__item {
    display: flex;
    min-height: 86px;
    height: 100%;
    padding: 14px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: linear-gradient(180deg, #064885 0%, #74B5EB 100%);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.10);
}

.modal-charts-block-data__item-title {
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.modal-charts-block-data__item-title-date {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.modal-charts-block-data__item-percent-text {
    color: #1B1D1C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
</style>

