<template>
  <div class="ninth-component">
    <div class="home-component-title site-width"><h2>New Innovations</h2></div>

    <div class="ninth-component-blocks site-width">
      <div class="ninth-component-block1">
        <img
          class="ninth-component-block1__logo"
          src="/img/spotlite-logo.png"
          alt=""
        />

        <div class="ninth-component-block1__text">
          <p>
            Unlock Spotlite: Instantly access ad creatives, launch dates,
            activity metrics, and rankings right from your smartphone. Empower
            your brand with real-time insights, anytime, anywhere, to elevate
            your strategy and execution.
          </p>
        </div>

        <AnimatingButton
          text="Learn more"
          class="learn-more-animating-button"
          @click="openPage"
        />
      </div>
      <img
        class="small-component-bg"
        src="/img/ninth-component-bg2.png"
        alt=""
      />
    </div>

    <img
      class="pic-component-bg"
      ref="picComponentBg"
      src="/img/phone_full.png"
      alt=""
    />
    <!-- <img
      class="pic-component-bg"
      ref="picComponentBg"
      src="/img/ninth-component-bg1.png"
      alt=""
    /> -->
    <!--        <img class="pic-component-bg2" ref="picComponentBg2" src="/img/ninth-component-bg1200.png" alt="">-->
    <!--        <img class="pic-component-bg3" ref="picComponentBg3" src="/img/ninth-component-bg992.png" alt="">-->
  </div>
</template>

<script>
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "NinthComponent",
  props: ["innerWidth"],
  components: { AnimatingButton },
  data() {
    return {
      maxOffset: 600,
    };
  },
  methods: {
    openPage() {
      window.open("https://spotliteai.com", "_blank");
    },
    changeInnerWidth() {
      if (this.innerWidth < 1920) {
        const baseWidth = 1920;
        const widthDifference = baseWidth - this.innerWidth;

        if (this.innerWidth < 1300) {
          this.maxOffset = 350;
        }

        if (this.innerWidth < 992) {
          this.maxOffset = 300;
        }

        const offset = (widthDifference / baseWidth) * this.maxOffset + 200;
        this.$refs.picComponentBg.style.right = "-" + offset + "px";
        // this.$refs.picComponentBg2.style.right = '-' + offset + 'px';
        // this.$refs.picComponentBg3.style.right = '-' + offset + 'px';
      } else {
        this.$refs.picComponentBg.style.right = "-60px";
      }
    },
  },
  mounted() {
    this.changeInnerWidth();
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
  },
};
</script>

<style>
.ninth-component .home-component-title {
  margin-bottom: 100px;
}

.ninth-component .pic-component-bg {
  position: absolute;
  right: -12vw;
  top: 80px;
  height: 840px;
  z-index: 0;
}

.ninth-component {
  margin-top: 240px;
  position: relative;
  min-height: 920px;
}

.ninth-component-blocks {
  margin-top: 179px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  z-index: 5;
}

.ninth-component-block1__text {
  color: #666;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
  margin-top: 32px;
}

.ninth-component-block1 {
  width: 45%;
}

.ninth-component-block1__logo {
  width: 212px;
}

.ninth-component .learn-more-animating-button {
  margin-top: 60px;
  width: 240px;
  text-transform: uppercase;
  margin-left: 20px;
}

@media (max-width: 1600px) {
  .ninth-component {
    margin-top: 120px !important;
    min-height: 780px;
  }

  .ninth-component .pic-component-bg {
    height: 700px;
  }

  .ninth-component-blocks {
    margin-top: 100px;
  }

  .ninth-component-block1__text {
    font-size: 25px;
    line-height: 40px;
  }
}

@media (max-width: 1300px) {
  .ninth-component-blocks {
    margin-top: 135px;
  }

  .ninth-component .learn-more-animating-button {
    margin-top: 39px;
  }

  .ninth-component .pic-component-bg {
    /*display: none !important;*/
  }

  .ninth-component .pic-component-bg2 {
    /*display: block;*/
  }
}

@media (max-width: 992px) {
  .ninth-component-blocks {
    margin-top: 59px;
  }

  .ninth-component .pic-component-bg3 {
    display: block;
  }

  .ninth-component-block1__text {
    font-size: 24px;
  }

  .ninth-component-block1__logo {
    width: 304px;
  }
}

@media (max-width: 768px) {
  .ninth-component-blocks {
    margin-top: 51px;
  }
}

@media (max-width: 767px) {
  .ninth-component .learn-more-animating-button,
  .ninth-component .animating-button {
    width: 100%;
  }

  .ninth-component-block1__text {
    line-height: normal;
    font-size: 32px;
  }

  .ninth-component .small-component-bg {
    padding: 22px;
  }

  .ninth-component .pic-component-bg,
  .ninth-component .pic-component-bg2 {
    display: none;
  }

  .ninth-component-blocks {
    flex-direction: column;
  }

  .small-component-bg {
    display: block !important;
    width: 60vw !important;
  }

  .ninth-component-block1 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .ninth-component-block1__logo {
    width: 181px;
  }

  .ninth-component .learn-more-animating-button {
    margin-top: 16px;
  }

  .small-component-bg {
    display: block !important;
    width: 303px !important;
  }

  .ninth-component-blocks {
    flex-direction: column;
    margin-top: 40px;
  }
}

@media (max-width: 303px) {
  .small-component-bg {
    display: block !important;
    width: 85vw !important;
  }
}
</style>
