<template>
    <div class="modal-charts-block-chart">
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue';

export default {
    name: "HighchartsModal",
    components: {highcharts: Chart},
    props: ['data'],
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    height: 200
                },
                xAxis: {
                    categories: [],
                },
                series: [{
                    name: 'Share %',
                    data: [],
                    marker: {
                        fillColor: '#064885',
                        lineColor: '#fff',
                    },
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, '#064885'],
                            [1, '#90c4ee']
                        ]
                    }
                }],
                title: {
                    text: ''
                },
                yAxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    max: 100,
                    tickInterval: 50,
                    tickPositions: [0, 50, 100],
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
            }
        }
    },
    methods: {
        parseData() {
            this.data.mediaActivity.forEach(item => {
                this.chartOptions.series[0].data.push(item.value);
                this.chartOptions.xAxis.categories.push(item.text);
            });
        },
    },
    mounted() {
        if (this.data) {
            this.parseData();
        }
    }
}
</script>

<style scoped>

</style>
