<template>
    <div class="case-study-block-text site-width">
        <div class="case-study-block-text__title">{{ title }}</div>

        <div class="case-study-block-text__items">
            <div v-for="(item, index) in items" :key="index" class="case-study-block-text__item">
                <img src="/img/ourSolutionsPage/ico-case-study.png" alt="ico-case-study"
                     class="case-study-block-text__item-number">
                <div>
                    <div v-if="item.title" class="case-study-block-text__item-title">
                        {{ item.title }}
                    </div>
                    <div v-if="item.text" class="case-study-block-text__item-text">
                        {{ item.text }}
                    </div>
                </div>

            </div>
        </div>

        <div class="case-study-block-text__purpose" v-if="purpose">
            <div class="case-study-block-text__purpose-block">
                <div class="case-study-block-text__purpose-title">
                    <img src="/img/ourSolutionsPage/star-circle.png" alt="star-circle">
                    Purpose:
                </div>
                <div class="case-study-block-text__purpose-text">
                    {{ purpose }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CaseStudyBlockText",
    props: ['title', 'purpose', 'items']
}
</script>

<style>
.case-study-block-text {
    margin-top: 100px;
}

.case-study-block-text__title {
    color: #232429;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -2.08px;
    margin-bottom: 52px;
}

.case-study-block-text__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 32px;
}

.case-study-block-text__item-number {
    margin-top: 5px;
}

.case-study-block-text__item-text {
    color: #232429;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.64px;
}

.case-study-block-text__item-title {
    color: #232429;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: -2px;
}

.case-study-block-text__purpose {
    display: inline-block;
}

.case-study-block-text__purpose-block {
    background: #F2F6FD;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    margin-top: 40px;
}

.case-study-block-text__purpose-title {
    color: #232429;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-right: 8px;
}

.case-study-block-text__purpose-title img {
    width: 36px;
    margin-right: 8px;
}

.case-study-block-text__purpose-text {
    color: #232429;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4px;
}

@media (max-width: 1300px) {
    .case-study-block-text__item-text {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.56px;
    }

    .case-study-block-text__item-title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.56px;
    }

    .case-study-block-text__purpose {
        width: 100%;
        display: flex;
    }

    .case-study-block-text__purpose-block {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .case-study-block-text__purpose-title {
        margin-bottom: 8px;
    }
}

@media (max-width: 992px) {
    .case-study-block-text__item-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.4px;
    }

    .case-study-block-text__item-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.72px;
    }
}

@media (max-width: 768px) {
    .case-study-block-text__item-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.36px;
    }

    .case-study-block-text__item-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.36px;
    }
}

@media (max-width: 500px) {
    .case-study-block-text__item-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .case-study-block-text__item-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -1.2px;
    }
}
</style>