<template>
  <div class="seventh-component">
    <div class="home-component-title site-width">
      <h2>Using Adease To Stay Ahead</h2>
    </div>
    <!-- <div class="marquee3k" data-speed="2">
      >
      <div class="child">
        <img
          :src="item.url"
          :alt="item.alt"
          v-for="(item, index) in items"
          :key="index"
        />
      </div>
    </div> -->

    <div class="seventh-component-carousel">
      <div class="animated fadeInRight">
        <div class="marquee3k" data-speed="1">
          >
          <div class="child">
            <img
              :src="item.url"
              :alt="item.alt"
              v-for="(item, index) in items1"
              :key="index"
            />
          </div>
        </div>
      </div>
      <div class="animated fadeInLeft">
        <div class="marquee3k" data-speed="1" data-reverse="true">
          >
          <div class="child">
            <img
              :src="item.url"
              :alt="item.alt"
              v-for="(item, index) in items2"
              :key="index"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "SeventhComponent",

  props: ["innerWidth"],

  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        cssEase: "linear",
      },
      settings2: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: false,
        rtl: true,
      },
      items2: [
        { url: "/img/temp/acb.png", alt: "Icon 1" },
        { url: "/img/temp/Bell.png", alt: "Icon 2" },
        { url: "/img/temp/Clip-path-group.png", alt: "Icon 3" },
        { url: "/img/temp/nlogicsvg.png", alt: "Icon 4" },

        { url: "/img/temp/Mask-group2.png", alt: "Icon 5" },
        { url: "/img/temp/Mask-group3.png", alt: "Icon 6" },
        { url: "/img/temp/Mask-group4.png", alt: "Icon 7" },
        { url: "/img/temp/Mask-group5.png", alt: "Icon 8" },
      ],
      items1: [
        { url: "/img/temp/brandsvg.png", alt: "Icon 9" },
        {
          url: "/img/temp/Pattison_Outdoor_Advertising_logo.png",
          alt: "Icon 10",
        },
        { url: "/img/temp/Unilever.png", alt: "Icon 11" },
        { url: "/img/temp/WW_BIG.png", alt: "Icon 12" },
        { url: "/img/temp/kantar.png", alt: "Icon 13" },
        { url: "/img/temp/Mask-group1.png", alt: "Icon 14" },
        { url: "/img/temp/media.png", alt: "Icon 15" },
        { url: "/img/temp/general-mills.png", alt: "Icon 16" },
      ],
    };
  },
  computed: {
    randomItems1() {
      return this.items.slice().sort(() => Math.random() - 0.5);
    },
    randomItems2() {
      return this.items.slice().sort(() => Math.random() - 0.5);
    },
  },
  methods: {
    changeInnerWidth() {
      if (this.innerWidth > 1200) {
        this.settings.slidesToShow = 4;
        this.settings2.slidesToShow = 4;
      } else if (this.innerWidth < 1200 && this.innerWidth > 1024) {
        this.settings.slidesToShow = 3;
        this.settings2.slidesToShow = 3;
      } else if (this.innerWidth < 1024 && this.innerWidth > 768) {
        this.settings.slidesToShow = 3;
        this.settings2.slidesToShow = 3;
      } else {
        this.settings.slidesToShow = 2;
        this.settings2.slidesToShow = 2;
      }
    },
  },
  mounted() {
    this.changeInnerWidth();
    setTimeout(() => {
      console.log("running here");
    }, 3000);
  },
  watch: {
    innerWidth: function () {
      this.changeInnerWidth();
    },
  },
};
</script>

<style lang="scss">
.slick-list {
  box-sizing: content-box;
}
.marquee3k {
  * {
    white-space: nowrap;
  }
  .child {
    width: max-content;
    height: 70px;
    @media screen and (max-width: 768px) {
      height: 40px;
    }
    img {
      height: 100%;
    }
  }
}

.marquee3k__copy {
  img {
    padding-right: 100px;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
      padding-right: 50px;
    }
  }
}

.seventh-component-carousel {
  display: flex;
  flex-direction: column;
  gap: 96px;
  @media screen and (max-width: 768px) {
    gap: 40px;
  }

  // img {
  //   display: block;
  //   box-sizing: border-box;
  //   // width: 100%;
  //   height: 100%;
  //   position: relative;
  //   display: flex;
  //   &:after {
  //     content: "";
  //     display: block;
  //     width: 100px;
  //     height: 100px;
  //     background: red;
  //   }
  // }
}

.logo-block2 {
  margin-top: 80px;
  margin-bottom: 150px;
}

.seventh-component-carousel-div {
}

.seventh-component {
  margin-top: 250px;
}

.seventh-component-carousel {
  margin-top: 80px;
}

.seventh-component-carousel .slick-track {
  display: flex !important;
  align-items: center;
}

@media (max-width: 1600px) {
  .seventh-component {
    margin-top: 120px;
  }

  .seventh-component-carousel-div {
    height: 45px;
  }

  .seventh-component-carousel-div img {
    height: 100%;
  }
}

@media (max-width: 992px) {
  .logo-block2 {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .seventh-component-carousel,
  .seventh-component-carousel .logo-block2 {
    margin-top: 20px !important;
  }

  .seventh-component-carousel-div {
    height: 38px;
  }
}
</style>
