<template>
    <div class="first-component" style="background-image: url('/img/first-component.png')">
        <AnimatingTextComponent :text="text"/>
    </div>
</template>

<script>
import AnimatingTextComponent from "@/components/homePage/AnimatingTextComponent.vue";

export default {
    name: "FirstComponent",
    components: {AnimatingTextComponent},
    props: ['text'],
    methods: {}
}
</script>

<style>
.first-component {
    position: relative;
    margin-top: -108px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (max-width: 1600px) {
    .first-component {
        height: 600px;
    }

    .first-component-text {
        font-size: 50px;
        line-height: 60px;
        column-gap: 15px;
    }
}

@media (max-width: 1500px) {
    .first-component-text {
        column-gap: 13px;
    }
}

@media (max-width: 992px) {
    .first-component {
        height: 420px;
    }

    .first-component-text {
        font-size: 37px;
        padding: 23px 23px 23px 0;
        letter-spacing: -0.37px;
    }
}

@media (max-width: 768px) {
    .first-component-text {
        font-size: 30px;
        padding: 16px;
    }
}

</style>
