<template>
  <AppLayout>
    <div class="prospector-screen-component">
      <div
        class="prospector-screen-component-head"
        style="background-image: url('/img/ourSolutionsPage/Prospector.jpg')"
      >
        <div class="prospector-screen-component-head-content">
          <div class="prospector-screen-component-head-block">
            <div class="prospector-screen-component-head-title">
              <h1>SOLUTIONS</h1>
            </div>

            <div class="prospector-screen-component-head-text">
              <h2>Prospector</h2>
            </div>

            <div class="prospector-screen-component-head-description">
              <p>
                Uncover new business leads by exploring who advertises what,
                when, and where. Gain insights into market trends and competitor
                actions, helping you identify opportunities for growth and
                collaboration.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="site-width prospector-screen-component-block">
        <div class="prospector-screen-component-block-title">
          <h2>Prospector Data: Revolutionizing Partner Outreach with AI</h2>
        </div>
        <div class="prospector-screen-component-block-text">
          <p>
            Welcome to the cutting-edge of advertising efficiency—our Prospector
            Data service. Harnessing the power of Artificial Intelligence, we
            delve into the vast expanse of advertising occurrence data to
            pinpoint the top prospects for our partners each week. Our AI-driven
            approach not only identifies but also ranks these prospects based on
            their recent market activities, ensuring that your sales
            representatives are equipped with the most promising contacts.
          </p>
        </div>
        <div class="prospector-screen-component-block-border"></div>

        <div class="prospector-screen-component-block-title">
          <h2>Tailored Outreach Scripts for Maximum Impact</h2>
        </div>
        <div class="prospector-screen-component-block-text">
          <p>
            Each prospect comes with a meticulously crafted outreach script.
            These scripts are personalized, explaining precisely why your rep is
            reaching out. Factors influencing these tailored messages include:
          </p>
        </div>
        <div class="prospector-screen-component-items">
          <div
            v-for="(item, key) in items"
            :key="key"
            class="prospector-screen-component-item"
          >
            <img src="/img/ourSolutionsPage/Ellipse 20.png" alt="Ellipse" />
            <div>
              <div class="prospector-screen-component-item-title">
                <h3>{{ item.title }}</h3>
              </div>
              <div class="prospector-screen-component-item-text">
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="prospector-screen-component-block-border"></div>

        <div class="prospector-screen-component-block-title">
          <h2>Why Prospector Data?</h2>
        </div>
        <div class="prospector-screen-component-block-text">
          <p>
            Our AI-driven Prospector Data service ensures that every interaction
            is strategic, timely, and based on data-driven insights. This
            precision leads to higher engagement rates, making each sales call
            more effective and increasing the likelihood of successful
            partnerships. Empower your sales team with Prospector Data – where
            AI meets strategic outreach.
          </p>
        </div>
        <AnimatingButton
          text="VIEW CASE STUDY"
          @click="() => $router.push({ name: 'CaseStudyScreen' })"
        />
      </div>

      <ContactUsComponent />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import ContactUsComponent from "@/components/homePage/ContactUsComponent.vue";
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "ProspectorScreen",
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  components: { AnimatingButton, ContactUsComponent, AppLayout },
  data() {
    return {
      seo: {
        title: "Prospector Screen - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      items: [
        {
          title: "Increased Market Presence",
          text: "Highlight prospects that are making waves in the market, suggesting an opportune moment for engagement.",
        },
        {
          title: "New Market Entries",
          text: "Identify companies venturing into new markets, presenting a chance to be part of their growth journey.",
        },
        {
          title: "Product Launches",
          text: "Catch the wave of excitement generated by new products and offer timely partnerships.",
        },
        {
          title: "Seasonal Advertising Trends",
          text: "Utilize historical data to target prospects who are likely to advertise during the same period as the previous year.",
        },
        {
          title: "Shifts in Messaging",
          text: "Leverage changes in a company’s advertising message to propose strategic alignments with their evolving brand narrative.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.prospector-screen-component {
}

.prospector-screen-component-head {
  position: relative;
  margin-top: -108px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.prospector-screen-component-head-content {
  width: 100%;
  padding: 90px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(60px);
}

.prospector-screen-component-head-block {
  max-width: 1520px;
  margin: 0 auto;
}

.prospector-screen-component-head-title {
  color: #aec6f4;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1.28px;
  margin-bottom: 32px;
}

.prospector-screen-component-head-text {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
  letter-spacing: -2.56px;
  margin-bottom: 24px;
}

.prospector-screen-component-head-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
}

.prospector-screen-component-items {
  margin-top: 40px;
  padding: 0 14px;
}

.prospector-screen-component-item {
  display: flex;
  align-items: flex-start;
  gap: 22px;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.prospector-screen-component-item img {
  padding-top: 14px;
}

.prospector-screen-component-item-title {
  color: #232429;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: -2px;
  margin-bottom: 11px;
}

.prospector-screen-component-item-text,
.prospector-screen-component-block-text {
  color: #4d4d4d;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.prospector-screen-component-block {
  margin-top: 160px;
  margin-bottom: 160px;
}

.prospector-screen-component-block .animating-button {
  margin-top: 100px;
  width: 100%;
}

.prospector-screen-component-block-title {
  color: #232429;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -2.08px;
  margin-bottom: 32px;
}

.prospector-screen-component-block-border {
  width: 100%;
  margin: 64px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 1300px) {
  .prospector-screen-component-head {
    height: 800px;
  }

  .prospector-screen-component-head-content {
    padding: 52px 60px;
  }

  .prospector-screen-component-head-title {
    font-size: 22px;
    margin-bottom: 8px;
    letter-spacing: -0.44px;
  }

  .prospector-screen-component-head-text {
    font-size: 48px;
    margin-bottom: 16px;
    line-height: 64px;
    letter-spacing: -1.92px;
  }

  .prospector-screen-component-head-description {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  .prospector-screen-component-block-title {
    color: #232429;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -1.92px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    letter-spacing: -0.2px;
  }

  .prospector-screen-component-item-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

@media screen and (max-width: 992px) {
  .prospector-screen-component-head {
    height: 720px;
  }

  .prospector-screen-component-head-content {
    padding: 52px 60px;
  }

  .prospector-screen-component-head-title {
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    margin-bottom: 8px;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-head-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  .prospector-screen-component-item-title {
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.72px;
  }
}

@media screen and (max-width: 768px) {
  .prospector-screen-component-head {
    height: 650px;
  }

  .prospector-screen-component-head-content {
    padding: 40px;
  }

  .prospector-screen-component-head-title {
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-head-description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -1.28px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .prospector-screen-component-block-border {
    margin: 40px 0;
  }

  .prospector-screen-component-item-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.36px;
  }
}

@media screen and (max-width: 500px) {
  .prospector-screen-component-head {
    height: 790px;
  }

  .prospector-screen-component-head-content {
    padding: 32px;
  }

  .prospector-screen-component-head-title {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 24px;
    letter-spacing: -0.54px;
  }

  .prospector-screen-component-head-text {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }

  .prospector-screen-component-head-description {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
  }

  .prospector-screen-component-block-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.64px;
    margin-bottom: 24px;
  }

  .prospector-screen-component-block-text,
  .prospector-screen-component-item-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.18px;
  }

  .prospector-screen-component-item-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1.2px;
  }
}
</style>
