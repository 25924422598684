<template>
  <div class="eighth-component" id="our-latest-insights">
    <div class="home-component-title"><h2>Our latest insights</h2></div>
    <div class="eighth-component-blocks" v-if="items.length">
      <div
        v-if="items[0]"
        @click="openArticle(items[0].id)"
        class="eighth-component-block1 position-relative pointer"
        v-animate-onscroll="'animated'"
      >
        <img :src="items[0].image" ref="img1" alt="Our latest insights" />
        <div class="eighth-component-block-decription backdrop-filter-blur">
          <div class="eighth-component-block-decription__title">
            {{ items[0].name }}
          </div>
          <div class="eighth-component-block-decription__text">
            {{ items[0].summary }}
          </div>
        </div>
      </div>
      <div class="eighth-component-block2" v-animate-onscroll="'animated'">
        <div
          v-if="items[1]"
          @click="openArticle(items[1].id)"
          class="eighth-component-block2-1 position-relative pointer"
          ref="block2_1"
        >
          <img :src="items[1].image" alt="Our latest insights" ref="img2" />
          <div class="eighth-component-block-decription backdrop-filter-blur">
            <div class="eighth-component-block-decription__title">
              {{ items[1].name }}
            </div>
            <div class="eighth-component-block-decription__text">
              {{ items[1].summary }}
            </div>
          </div>
        </div>
        <div
          v-if="items[2]"
          @click="openArticle(items[2].id)"
          class="eighth-component-block2-2 position-relative pointer"
          ref="block2_2"
        >
          <img :src="items[2].image" alt="Our latest insights" />
          <div class="eighth-component-block-decription backdrop-filter-blur">
            <div class="eighth-component-block-decription__title">
              {{ items[2].name }}
            </div>
            <div class="eighth-component-block-decription__text">
              {{ items[2].summary }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "EighthComponent",
  props: ["items", "innerWidth"],
  // components: {AnimatingButton},
  methods: {
    openPage() {
      // this.$router.push('/insights')
    },

    openArticle(id) {
      window.open("/article/" + id, "_blank");
    },
    adjustImageHeight() {
      if (this.$refs.img2) {
        if (this.innerWidth < 992) {
          const block1Height = this.$refs.img2.clientHeight;
          this.$refs.img1.style.height = block1Height + "px";
        } else {
          this.$refs.img1.style.height = "100%";
        }
      }
    },
  },
  mounted() {
    this.adjustImageHeight();
    window.addEventListener("resize", this.adjustImageHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustImageHeight);
  },
};
</script>

<style>
.eighth-component-block-decription {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 52px 40px;
  width: 100%;
  cursor: pointer;
}

.eighth-component-block-decription__title {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eighth-component-block-decription__text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.eighth-component {
  padding: 120px 0;
}

.eighth-component-blocks {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  margin: 60px 0;
}

.eighth-component-block2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.eighth-component-block1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.eighth-component-block1,
.eighth-component-block2 {
  width: 50%;
}

.eighth-component-block1 {
  overflow: hidden;
  position: relative;
  height: 920px;
}

.eighth-component-block2-2,
.eighth-component-block2-1 {
  overflow: hidden;
  position: relative;
  height: 455.2px;
}

.eighth-component-block2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.view-all__btn {
  cursor: pointer;
  background: #232429;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 240px;
  padding: 16px;
}

@media (min-width: 1920px) {
  .eighth-component-block-decription__title {
    font-size: 40px;
  }

  .eighth-component-block-decription__text {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .eighth-component {
    padding: 60px 0;
  }

  .eighth-component-block1 {
    height: 620px;
  }

  .eighth-component-block2-1,
  .eighth-component-block2-2 {
    height: 305.2px;
  }

  .eighth-component-block-decription__title {
    font-size: 30px;
  }

  .eighth-component-block-decription__text {
    line-height: 25px;
  }

  .eighth-component-block-decription {
    padding: 40px 40px;
  }

  .eighth-component-blocks {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .eighth-component-blocks {
    flex-direction: column;
  }

  .eighth-component-block1,
  .eighth-component-block2 {
    width: 100%;
  }

  .eighth-component-block-decription__title {
    font-size: 40px;
  }

  .eighth-component-block-decription__text {
    font-size: 20px;
  }

  .eighth-component-blocks {
    display: flex;
  }

  .eighth-component-block2 img {
    object-fit: initial;
    position: relative;
  }

  .eighth-component-block1 {
    height: 100%;
  }

  .eighth-component-block1 img {
    object-fit: cover;
    position: relative;
  }
}

@media (max-width: 767px) {
  .eighth-component-block-decription__title {
    font-size: 20px;
  }

  .eighth-component-block-decription__text {
    font-size: 14px;
  }

  .eighth-component-animating-button,
  .eighth-component-animating-button .animating-button {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .eighth-component-block-decription__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .eighth-component-block-decription__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
