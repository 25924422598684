<template>
  <AppLayout>
    <div class="case-study-screen-component">
      <div
        class="case-study-screen-component-head"
        style="
          background-image: url('/img/ourSolutionsPage/competitive-pricing-case-study.jpg');
        "
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-study-screen-component-head-title">
              CASE STUDY 2024
            </div>
            <div class="case-study-screen-component-head-text">
              Navigating the Ad Landscape: Strategic Insights from Competitive
              Pricing Data
            </div>
          </div>
        </div>
      </div>

      <div class="case-study-screen-component-block__blue background-white">
        <div class="site-width">
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Empowering decision-making</div>
            <div class="block__blue-text">
              <div>
                Adease Advertising Intelligence harnesses cutting-edge AI
                technology to simplify access to promotional data, enabling
                cost-effective solutions for our clients and eliminating the
                need for internal data systems.
              </div>
              <div>
                We provide real-time, tailored advertising intelligence that
                adapts quickly to market changes.
              </div>
              <div>
                Our innovative products are specifically crafted to enhance
                audience engagement, reinforcing our commitment to leadership in
                the advertising intelligence sector.
              </div>
            </div>
          </div>
          <div class="case-study-screen-component-block__blue-divider"></div>
          <div class="case-study-screen-component-block__blue-block">
            <div class="block__blue-title">Introduction</div>
            <div class="block__blue-text">
              <div>
                In an era where multimedia advertising is crucial for brand
                success, understanding how to strategically deploy radio and TV
                ads can significantly impact market presence and consumer
                engagement.
              </div>
              <div>
                This case study delves into how companies can harness the power
                of radio and TV creative data to refine their advertising
                strategies, monitor competitor activities, and enhance brand
                messaging.
              </div>
              <div>
                By leveraging this data, brands can adapt and innovate their
                approaches to not only match but surpass their competition in
                the dynamic advertising arena.
              </div>
            </div>
          </div>
        </div>
      </div>

      <CaseStudyBlockImage
        image="/img/ourSolutionsPage/competitive-pricing-case-study-2.jpg"
        :text="'Key Performance Indicators for <br> Competitive Analysis'"
      />

      <CaseStudyBlockText title="Competitor Analysis" :items="items1" />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Promotion Effectiveness Analysis"
        :items="items2"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Discount and Offer Monitoring"
        :items="items3"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="New Product Pricing Detection"
        :items="items4"
      />

      <div class="site-width case-study-screen-component-divider"></div>

      <CaseStudyBlockText
        title="Creative Lifecycle Management"
        :items="items5"
      />

      <CaseStudyBlockConclusion
        :text="conclusion"
        class="case-study-block-image-mt"
      />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudyBlockImage from "@/components/CaseStudyBlockImage.vue";
import CaseStudyBlockText from "@/components/CaseStudyBlockText.vue";
import CaseStudyBlockConclusion from "@/components/CaseStudyBlockConclusion.vue";

import "/src/css/case-study-styles.css";

export default {
  name: "CompetitiveBrandsCaseStudyScreen",
  components: {
    CaseStudyBlockConclusion,
    CaseStudyBlockText,
    CaseStudyBlockImage,
    AppLayout,
  },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Competitive Pricing - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      conclusion:
        "Employing competitive pricing data provides brands with a granular view of the advertising landscape, essential for making informed strategic decisions. This access to competitor insights and market dynamics allows brands to proactively adjust and optimize their pricing and promotional efforts, ensuring they not only meet but exceed consumer expectations and remain competitive in the bustling market.",
      items1: [
        {
          title: "Objective",
          text: "Analyze the pricing strategies of competitors in flyer promotions and e-commerce to refine your own pricing models.",
        },
        {
          title: "Outcome",
          text: "Company X utilized competitive pricing data to assess the most advertised products and their price points. Insights gathered allowed them to adjust their pricing strategies, leading to a 20% increase in sales and a 15% improvement in customer acquisition by offering more attractive pricing options.",
        },
      ],
      items2: [
        {
          title: "Objective",
          text: "Understand the effectiveness of promotional strategies used by competitors to enhance your own promotional campaigns.",
        },
        {
          title: "Outcome",
          text: "Company X analyzed the success of various promotional tactics employed by competitors. By adopting successful strategies and avoiding less effective ones, they achieved a 25% increase in promotion-related sales and a 10% higher customer retention rate.",
        },
      ],
      items3: [
        {
          title: "Objective",
          text: "Identify and respond to competitors' discounting strategies to optimize your own discount offers.",
        },
        {
          title: "Outcome",
          text: "By tracking special offers and discounts advertised by competitors, Company X was able to launch timely counter-promotions, resulting in a 30% uptick in sales during the promotional periods and a 5% boost in market share.",
        },
      ],
      items4: [
        {
          title: "Objective",
          text: "Quickly identify and understand the pricing strategies surrounding new product launches by competitors.",
        },
        {
          title: "Outcome",
          text: "Company X effectively spotted and analyzed new product prices by competitors, enabling them to launch competitive pricing strategies for similar products, which increased their new product uptake by 40%.",
        },
      ],
      items5: [
        {
          title: "Objective",
          text: "Distinguish between new and ongoing pricing strategies to optimize the lifecycle of your promotional content.",
        },
        {
          title: "Outcome",
          text: "Through meticulous tracking and analysis, Company X managed their promotional assets more efficiently, retiring outdated promotions and extending successful ones, which enhanced overall ad performance by 50%.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
